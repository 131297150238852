import React from 'react'
import {useGetAllSoftSkills } from "../../api/General/useGetAllSoftSkills";
import {SoftSkillSpan } from "../JobSeekerProfile/SoftSkillSpan";

interface SoftSkillsProps{
softSkills:Array<string>
}

export const SoftSkills:React.FC<SoftSkillsProps> = ({softSkills}) => {
    const {softSkills:allSoftSkills} = useGetAllSoftSkills();

    return <div className="flex flex-wrap items-center  gap-2 pt-2 pb-4">
         {
           allSoftSkills.length>0 &&  softSkills.map((softSkillId) => {
                 return <SoftSkillSpan key={softSkillId} name={allSoftSkills?.filter(i=>i.id===softSkillId)[0]?.name} onClick={() => console.log("selected")} unicode={ allSoftSkills?.filter(i=>i.id===softSkillId)[0]?.unicode}/>
             })
          }
        </div>
   
    
}
