import React from 'react'
import {Icon } from "../Icons";

interface BusinessSideBarHeaderProps extends BaseInterface{
    onClick: () => void;
    active: boolean;
}


export const BusinessSideBarHeader:React.FC<BusinessSideBarHeaderProps> = ({onClick, active}) => {
    return (
        <div onClick={onClick} className="bg-purple-200 flex flex-col px-2 space-y-1 relative py-2  cursor-pointer">
            <div className="w-48  bg-white rounded-lg h-5"></div>
            <div className="w-1/2 bg-white rounded-lg h-5"></div>
            <div className="w-1/3 bg-white rounded-lg h-5"></div>
            <div className="w-1/4 bg-white rounded-lg h-5"></div>
            <div className="absolute rounded-xl border border-purple-700 h-8 bottom-2 right-5 bg-white flex items-center px-2 space-x-2 text-primary-accent "><Icon name="plus" /><div>Post a Job</div></div>
            {active && <div className="absolute -top-1 bottom-0 right-0 bg-primary-dark w-2 h-full rounded-sm flex items-center text-primary-light text-base"><Icon name="chevron_right"/></div>}
            </div>
    )
}
