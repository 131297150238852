import React from 'react'

interface ButtonSubmitPrimaryProps extends BaseInterface{
    onClick: () => void;
    active: boolean;
}


const ButtonSubmitPrimary:React.FC<ButtonSubmitPrimaryProps> = ({onClick, active, children, className=""}) => {
    return (
        <div onClick={onClick} className={`${className} ${active ?"bg-blue-950 ":"bg-gray-300"} font-semibold text-primary-light flex cursor-pointer rounded-3xl p-4 items-center justify-center`}>
           {children}
        </div>
    )
}

export { ButtonSubmitPrimary }
