import React, {useState, useEffect, useRef, useLayoutEffect} from 'react'
import {HamburgerMenu } from "./HamburgerMenu";
import { Logo} from "./Logo";
import {NavMenu } from "./NavMenu";

interface NavProps{
    user: User|null;
    className?: string;
    onRegisterClick: () => void;
    onLogOut: () => void;
    onLoginClick: () => void;
}


export const useScroll = (scrollFrom:number,scrollUntil:number) => {
    const [scrolledPosition, setScrolledPosition] = useState(0);
    const handleScroll = () => {
        setScrolledPosition((current) => {
            if (window.scrollY > scrollUntil) {
                return 1;
            }
            return window.scrollY/scrollUntil;
        });
   }
  
    useEffect(() => {
        window.addEventListener('scroll', handleScroll)
        return ()=>window.removeEventListener('scroll', handleScroll)
    }, [scrollUntil])
    
    return [scrolledPosition];
}



const Nav: React.FC<NavProps> = ({ className , onRegisterClick, onLogOut, onLoginClick, user}) => {
    const navBarRef = useRef<HTMLDivElement>(null);
    const [scrollUntil, setScrollUntil] = useState(12);
    const [scrolledValue]=useScroll(0,scrollUntil+30);


    useEffect(() => {
        if (navBarRef.current === null) {
            return;
        }
       setScrollUntil(navBarRef.current.offsetHeight)
    }, [])
    
    // useLayoutEffect(() => {
    //     if (navBarRef?.current?.style != null) {
    //         navBarRef.current.style.backgroundColor = `rgb(231, 229, 228, ${scrolledValue})`;
    //         // navBarRef.current.style.color = `rgba(162, 28, 175, 1)`;
    //     }
    // },[scrolledValue])

    return (
        <div ref={navBarRef} className={`${className} fixed left-0 right-0 text-primary opacity-90 h-16 flex items-center justify-between px-primary bg-blue-950`}>
            {/* <Logo className="text-primary-accent"/>
            <HamburgerMenu onLoginClick={onLoginClick} onLogOut={onLogOut} user={user} onRegisterClick={ onRegisterClick} />
            <NavMenu onLoginClick={onLoginClick} user={user} onLogOut={onLogOut} onRegisterClick={onRegisterClick }/> */}
           
            <div className="w-full flex justify-center ">
               <Logo className="text-white"/>
            </div>
        </div>
    )
}

export {Nav}
