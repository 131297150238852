import React, {useState, useEffect} from 'react'
import { SelectSearch } from "../SelectSearch";
import { fuzzySearch } from "../util/fuzzySearch";
import { sentenceCase} from "change-case";
import { Jobs } from "../../constants/jobs";
import { Languages} from "../../constants/languages";
import {useSkillsByJobId } from "../../api/Business/useSkillsByJobId";
import { FormLabel } from '../../components/Common';
import Calendar from 'react-calendar';
import "../../styles/calendar.css";
import { ButtonSubmitPrimary } from '../Common/ButtonSubmitPrimary';
import {db,firebase} from "../../firebase";


interface BusinessJobPostingFormProps{
    user: User | null;

}

const JobsArray: Array<any> = Jobs.map(job_id => {
    return {
        name:sentenceCase(job_id),
        value:job_id
}})

const LanguagesArray: Array<any> = Languages.map(job_id => {
    return {
        name:sentenceCase(job_id),
        value:job_id
}})

const JobTypeArray:Array<any>=[{name:"Full-Time", value:"full_time"},{name:"Part-time", value:"part_time"}]

const DaysArray = [
    { value: '1', name: 'Sun' },
    { value: '2', name: 'Mon' },
    { value: '3', name: 'Tue' },
    { value: '4', name: 'Wed' },
    { value: '5', name: 'Thu' },
    { value: '6', name: 'Fri' },
    { value: '7', name: 'Sat' },
  ]

const defaultFormData = { fixed_days: [], flexible: false, wage: 0, job_description: "", job_id: "", job_type: "", primary_language: "", skills: [], temporary: { start: new Date().toISOString(), end: new Date().toISOString() } }

export const BusinessJobPostingForm: React.FC<BusinessJobPostingFormProps> = ({user}) => {
    const [formData, setFormData] = useState<JobPostingFormData>(defaultFormData);
    const {  skills } = useSkillsByJobId({ job_id: formData.job_id });
    const [postable, setPostable] = useState<boolean>(false);


    useEffect(() => {

        if (formData.job_description.trim() === "") {
            setPostable(false);
            return;
        }

        if (formData.wage === 0||isNaN(formData.wage)) {
            setPostable(false);
            return;
        }

        if (formData.job_id === "") {
            setPostable(false);
            return;
        }

        if (formData.job_type === "") {
            setPostable(false);
            return;
        }

        if (formData.primary_language === "") {
            setPostable(false);
            return;
        }

        setPostable(true);

    },[formData])



    const onJobTitleChange = (e: any) => {
        setFormData(curr => {
            return {
                ...curr,
                job_id: e,
            }
        })
    }

    const onPrimaryLanguageChange = (newLanguage:any) => {
        setFormData((curr) => {
            return {
                ...curr,
                primary_language:newLanguage
            }
        })
    }

    const onSecondaryLanguageChange = (e: any) => {
        setFormData((curr) => {
            return {
                ...curr,
                optional_second_language:e
            }
        })
    }

    const onSkillToggle = (e: any) => {
        setFormData((curr) => {
            if (curr.skills.includes(e.target.name)) {
                const filteredSkills = curr.skills.filter(i => i !== e.target.name);
                return {
                    ...curr,
                    skills:filteredSkills
                }
            }
            return {
                ...curr,
                skills:[...formData.skills,e.target.name]
              }
           })
    }

    const onJobTypeChange = (e: any) => {
        setFormData((curr) => {
            return {
                ...curr,
                job_type:e
           }
       })
    }

    const onHourlyRateChange = (e:any) => {
        setFormData((curr) => {
            return {
                ...curr,
                wage:Number(e.target.value)
            }
        })
    }

    const onDescriptionChange = (e: any) => {
      
        setFormData((curr) => {
            return {
                ...curr,
                job_description:e.target.value
            }
        })
    }

    const onFlexibleClick = () => {
        console.log("clicked")
        setFormData((curr) => {
            return {
                ...curr,
                flexible:!curr.flexible
           }
        })
    }


    const onCalendarIntervalChange = (e: any) => {
        setFormData(curr => {
            return {
                ...curr,
                temporary: { start: e[0].toISOString(), end: e[1].toISOString() }
            }
        })
    }
            
   
    const postJob = async() => {
        if (!postable) {
            return;
        }
        await db.collection("job_postings").add({ ...formData, created_by: user?.uid, created_at: firebase.firestore.FieldValue.serverTimestamp(), status: "posted" });
        setFormData(curr=>defaultFormData)
    }
    

    return (
        <div className="flex flex-col ">
            <div className="p-2 tracking-tight font-light text-xl border-b"> Create a listing</div>
            <form className="flex flex-col space-y-4 py-4 px-2">
                <div className="flex flex-col md:flex-row">
            <div className="flex-1 space-y-4">
                <div>
                    <FormLabel required >Who do you want to hire?</FormLabel>
                    <SelectSearch onChange={onJobTitleChange} filterOptions={fuzzySearch} value={formData.job_id}  search options={JobsArray}  placeholder="Choose closest job that you're hiring for.. " />
                </div>
                        
                <div>
                    <FormLabel required >Hourly Rate</FormLabel>
                    <input type="text" onChange={onHourlyRateChange} placeholder="Hourly Rate"></input>
                </div>
                <div>
                    <FormLabel required>Job Type</FormLabel>
                    <SelectSearch onChange={onJobTypeChange} filterOptions={fuzzySearch} search options={JobTypeArray} value={formData.job_type} placeholder="Select the role... " />
                </div>
              <div>
                  <FormLabel required extraInfo="Please select one.">When are they needed for the job?</FormLabel>
                    <div className="flex space-x-4 items-center max-w-sm">
                    
                    <div onClick={onFlexibleClick} className={`${formData.flexible?"bg-primary-dark text-white":""} p-1 rounded cursor-pointer`}>Flexible</div>
                    
                    <SelectSearch  printOptions="on-focus" options={DaysArray} multiple search filterOptions={ fuzzySearch} placeholder="Fixed"/>
                                <Calendar
                                    selectRange
                                    onChange={onCalendarIntervalChange}
                                     value={[new Date(formData.temporary.start), new Date(formData.temporary.end)]}
                                    />
                  </div>
                </div>
                        
                <div>
                    <FormLabel required>What are the job duties and responsibilities</FormLabel>
                    <textarea onChange={onDescriptionChange} placeholder="Job Description"/>
                </div>
                <div className="flex flex-col space-y-2">
                    <FormLabel required>Please select a language of your preference</FormLabel>
                    <SelectSearch onChange={onPrimaryLanguageChange} filterOptions={fuzzySearch} search options={LanguagesArray}  placeholder="Choose the primary language.. " />
                    <SelectSearch onChange={onSecondaryLanguageChange} filterOptions={fuzzySearch} search options={LanguagesArray}  placeholder="Select an optional second language.. " />
                    </div>
                    </div>
                    
                    <div className="flex-1 flex-col space-y-4 py-4 md:py-0">
                        <div className="flex flex-col">
                            <FormLabel>Photos and videos</FormLabel>
                            <div className="flex space-x-4 py-2">
                                <div className="h-20 w-20 rounded-xl bg-gray-300"></div>
                                <div className="h-20 w-20 rounded-xl bg-gray-300"></div>
                            </div>
                        </div>
                        <div className=" py-2">
                        <FormLabel required> Which of these skills do you value? </FormLabel>
                        {skills.length > 0 &&
                            skills.map((skill,i) => {
                                return <div key={i} className="">
                                    <label className="flex space-x-2 items-center">
                                        <input onClick={onSkillToggle} key={i} name={skill} type="radio" checked={formData.skills.includes(skill)} />
                                        <div> {skill }</div>
                                    </label>
                                </div>
                          })
                        }
                       </div>
                    </div>
                </div>
                <ButtonSubmitPrimary active={postable} onClick={ postJob}>Post </ButtonSubmitPrimary>
            </form>
        </div>
    )
}
