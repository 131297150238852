import { useEffect } from "react";

const useDisableBodyScroll = (open:boolean) => {
   useEffect(() => {
        open && (document.body.style.overflow = 'hidden');
        !open && (document.body.style.overflow = 'unset');
    }, [open]);
};

export { useDisableBodyScroll };
