import React, {useState, useEffect} from 'react'
import { JobSeekerMenu } from "./JobSeekerMenu";
import { Icon, IconNames } from "../Icons";
import { useListing } from '../../api/useListing';
import { applyJob, passJob, saveJob } from '../../api/useSubscribeJobAction';
import { capitalCase} from "change-case";
import { SmallHoverCard } from '../Common/SmallHoverCard';

interface JobSeekerJobListingProps {
    user: User | null;
}


interface ActionButtonJobListingProps {
    iconName: IconNames;
    primary: boolean;
    name: string;
    onClick: () => void;
}


const ActionButtonJobListing: React.FC<ActionButtonJobListingProps> = ({ iconName, primary, name, onClick }) => {

    return <div onClick={onClick} className={`${primary ? "bg-primary-dark text-primary-light" : "text-primary-accent bg-gray-200 "} flex-1 border  h-full rounded-lg flex items-center space-x-1 justify-center cursor-pointer hover:opacity-90 shadow-lg`}>
        <div className=""><Icon name={iconName} /></div>
        <div>{name}</div>
    </div>
}





const Map: React.FC = () => {
    return <div className=" border h-full w-full bg-gray-300">

    </div>
}



interface JobListingCardProps {
    onSaveClick: () => void;
    job_id: string;
    business_name: string;
    city: string;
    distance: string;
    wage: string;
    job_type: string;
    availability: Array<any>;
    primary_language: string;
    optional_second_language: string;
    job_description: string;
    is_new: boolean;
    temporary?: any;
    flexible?: boolean;
    fixed?: Array<string>;
}

const JobListingCard: React.FC<JobListingCardProps> = (props) => {

    return <div className="flex flex-col">
        <div className="h-28 bg-gray-300 relative">
        </div>
        <div className="hidden sm:flex h-14 p-2 max-w-lg">
            {props.children}
        </div>
        <div className=" p-4 border">
            <div className="w-full flex">
                <div className="w-1/2">
                    <div className="flex space-x-2 ">
                        <div className="underline text-lg">{capitalCase(props.job_id)}</div>
                        {props?.is_new && <div className="text-xs uppercase bg-lightest text-primary-accent mb-2 mt-1 rounded-sm">new</div>}
                    </div>
                    <div className="text-sm font-thin">
                        <div className="font-medium">{props.business_name}</div>
                        <div className="">{`${props.city}•${props.distance}`}</div>
                        <div className="py-4">
                            <div className="flex items-center space-x-2 ">
                                <div><Icon name="dollar" /></div>
                                <div>{props.wage}•{capitalCase(props.job_type)}</div>
                            </div>
                            <div className="max-w-lg flex items-center space-x-2">
                                <div className="pt-0.5"><Icon name="clock" /></div>
                                <div className="flex gap-2">
                                    {props.fixed && props.fixed.map(i => <SmallHoverCard key={i}>{i}</SmallHoverCard>)}
                                    {props.temporary && <><SmallHoverCard>{props.temporary.start.split("T")[0]} </SmallHoverCard><SmallHoverCard>{props.temporary.end.split("T")[0]} </SmallHoverCard></>}
                                    {props.flexible && <SmallHoverCard>{"flexible"} </SmallHoverCard>}
                                </div>
                            </div>
                            <div className="flex space-x-2 items-center">
                                <div><Icon name="star_filled" /></div>
                                     <div className="capitalize">{props.primary_language}</div>
                                     <div className="capitalize">{props.optional_second_language}</div>
                            </div>
                        </div>

                    </div>
                </div>

                <div className="hidden sm:w-1/2 sm:flex ">
                    <Map />
                </div>
            </div>
            <div className="w-full text-sm font-thin">
                {props.job_description}
            </div>
            <div className="sm:hidden h-28">
                <Map />
            </div>
        </div>
    </div>
}





const JobSeekerJobListing: React.FC<JobSeekerJobListingProps> = ({ children, user }) => {
    const [jobListings] = useListing({user_id:user?.uid||""});
    const [currentIndex, setCurrentIndex] = useState(0);


    const apply = async () => {
        console.log("applying")
        const result = await applyJob({ job_listing_id: jobListings[currentIndex]?.id, user_id: user?.uid || "" })
        if (result) {
            console.log("success")
        }
        setCurrentIndex(currentIndex+1)
    }

    const pass = async () => {
        const result = await passJob({ job_listing_id: jobListings[currentIndex]?.id, user_id: user?.uid || "" })
        if (result) {
            console.log("success")
        }
        setCurrentIndex(currentIndex+1)
    }

    const save = async () => {
        const result = await saveJob({ job_listing_id: jobListings[currentIndex]?.id, user_id: user?.uid || "" })
        if (result) {
            console.log("success")
        }
        setCurrentIndex(currentIndex+1)
    }


    return (
            <div className="flex flex-col">
                {/* <JobSeekerMenu /> */}
                {jobListings[currentIndex]?.job_id && <JobListingCard {...jobListings[currentIndex]} onSaveClick={save} >
                    <ActionButtonJobListing onClick={pass} iconName="briefcase" name="Pass" primary={false} />
                    <ActionButtonJobListing onClick={apply} iconName="briefcase" name="Apply" primary={true} />
                </JobListingCard>}
                <div className="fixed bg-white bg-opacity-80 left-0 right-0 bottom-0 border h-16 shadow-lg  sm:hidden z-10">
                    <div className="px-4 py-2 w-full h-full flex items-center space-x-2">
                        <ActionButtonJobListing onClick={pass} iconName="briefcase" name="Pass" primary={false} />
                        <ActionButtonJobListing onClick={apply} iconName="briefcase" name="Apply" primary={true} />
                    </div>
                </div>
            </div>
   
    )
}

export { JobSeekerJobListing }
