import React, {useEffect, useState} from 'react'
import { Icon} from "../Icons";


interface ListingSnippetCardProps {
    active: boolean;
    changeActiveListing: (activeListingId:string) => void;
    num_applicants: number;
    num_interviews: number;
    num_shortlist: number;
    job_id: string;
    wage: number;
    type: string;
    availability: Array<any>;
    job_listing_id: string;
}


export const ListingSnippetCard: React.FC<ListingSnippetCardProps> = ({active,changeActiveListing,job_listing_id, availability, job_id, num_applicants = 6, num_interviews = 2, num_shortlist = 1, type, wage }) => {
    
  
    return <div onClick={() => changeActiveListing(job_listing_id)}  key={job_listing_id} className="flex h-28">
       <div  className={` flex-1 border flex flex-col space-y-1 p-2 text-xs`}>
        <div className=" flex text-gray-500 space-x-1">
            <span>{num_applicants} applicants</span>
            <span>{num_interviews} interview</span>
            <span>{num_shortlist} shortlist</span>
        </div>
        <div className="underline text-base">
           {job_id}
        </div>
        <div className="flex space-x-1 ">
            <div>{`$${wage}/hr` }</div>
            <div>{type}</div>
        </div>
        <div className="flex space-x-1 text-gray-500">
            {
                availability?.map((item, i) => {
                    return <div key={i}>{ item.day}</div>
                })
            }
        </div>
        </div>
        {active && <div className="bg-primary-dark w-2 h-full rounded-sm flex items-center text-primary-light text-base"><Icon name="chevron_right"/></div>}
        </div>
}


interface BusinessSideBarProps{
    changeActiveJobListing: (job_listing_id: string) => void;
    activeJobListingId: string | null;
    isJobPostActive: boolean;
    user: User | null;
    jobListings: Array<any>;
}

export const BusinessSideBar:React.FC<BusinessSideBarProps> = ({changeActiveJobListing,activeJobListingId, children, isJobPostActive, user, jobListings}) => {


    useEffect(() => {
        if (jobListings.length === 0) {
            return;
        }
        if (activeJobListingId !== null) {
            return;
        }
        changeActiveJobListing(jobListings[0].job_listing_id);
    },[activeJobListingId, jobListings])


    
    
    return (
        <div>
           {children}
            {jobListings.map((jobListing, i) => {
                return <ListingSnippetCard active={ !isJobPostActive&&jobListing.job_listing_id===activeJobListingId} changeActiveListing={changeActiveJobListing} {...jobListing} />
            })}
        </div>
    )
}
