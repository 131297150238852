import React, { useState} from 'react'
import {Icon } from "../Icons";
import { sentenceCase } from "change-case";

interface EditableEducationProps{
    name: string;
    onSave: (name:string, value:Array<Education>) => void;
    value: Array<Education>;
}

export const EditableEducation: React.FC<EditableEducationProps> = ({ name, onSave, value }) => {
    const [isEditing, setIsEditing] = useState<boolean>(false);
    const [isNewFormOn, setIsNewFormOn] = useState<boolean>(false);
    
    const toggleIsEditing = () => {
        if (isEditing === true) {
            setIsNewFormOn(false);
            setIsEditing(false);
            return;
        }
        setIsEditing(!isEditing);
    }


    const onSubmit = (e: any) => {
        e.preventDefault();
        if (e.target.graduation.value.trim() === "" || e.target.institution.value.trim() === "" || e.target.major.value.trim() === "") {
            return;
        }
        setIsNewFormOn(false);
       
        onSave(name, [...value, {
           graduation_date : e.target.graduation.value,
           institution: e.target.institution.value,
            major:e.target.major.value,
        }])
    }

    const onDelete = (index:number) => {
        onSave(name, value.filter((item, i) => i!==index))
    }

    return (
        <div className="flex flex-col w-full space-y-2  ">
        <div className="flex justify-between">
            <div className="capitalize text-light">{sentenceCase(name)}</div>
            {!isEditing &&
            <div onClick={toggleIsEditing}><Icon name="pencil" /></div>
            }
             {isEditing &&
            <div className="flex">
              <div className="text-green-500 " onClick={toggleIsEditing} ><Icon name="check_fill" /></div>
            </div>
           }
        </div>


        <div className="flex flex-col">
            <div className=" flex flex-col leading-snug text-primary rounded-lg space-y-1 ">
                {value?.map((o, i) => <div className={`${isEditing &&"hover:text-delete"} flex space-between space-x-2 border p-1 rounded `}>
                    <div className="flex-1 flex-col">
                        <div>{o.major}</div>
                        <div> {o.institution}</div>
                        <div>{o.graduation_date }</div>
                     </div>
                    {isEditing&&<div onClick={()=>onDelete(i)}><Icon name="close_fill" /></div>}
                </div>)}
            </div>

            
            { isNewFormOn && <form onSubmit={onSubmit} className="flex space-x-2" >
                <div className="flex-1 flex flex-col">
                    <input name={"major"} placeholder={"Major"} />
                    <input name={"institution"} placeholder={"Name of Institution"} />
                    <input name={"graduation"} placeholder={"Date of Graduation"} />
                </div>
                <button type="submit" ><Icon name="add_fill" /></button>
            </form>
            }
           
            {!isNewFormOn &&<div onClick={() => setIsNewFormOn(true)} className="text-primary-accent underline flex items-center space-x-2 cursor-pointer">
                <Icon name="add_fill" />
                <div>Add more</div>
            </div>}
            </div>
    </div>
    )
}
