import React from 'react'
import {Link } from "../Common";
const MoreOptions = () => {
    return (
        <div className="flex flex-col items-center md:flex md:flex-row md:space-x-8">
            <Link className="link-hover">privacy statement</Link>
            <Link className="link-hover">terms of service</Link>
            <Link className="link-hover"> more about us</Link>
        </div>
    )
}

export { MoreOptions }
