import React, {useState, useRef} from 'react'
import {Icon } from "../Icons";

interface EditableArrayStringProps{
    name: string;
    onSave: (name:string, value:Array<string>) => void;
    value: Array<string>;
}

export const EditableArrayString: React.FC<EditableArrayStringProps> = ({ name, onSave, value }) => {
    const [isEditing, setIsEditing] = useState<boolean>(false);
    const inputBoxRef =useRef<HTMLInputElement>(null);

    const toggleIsEditing = () => {
        setIsEditing(!isEditing);
    }


    const valueSave = (name: string) => {
  
        if (inputBoxRef.current?.value.trim() === "") {
            return;
        }
      
        onSave(name, [...value, inputBoxRef.current?.value || ""]);
        if (inputBoxRef.current?.value) {
            inputBoxRef.current.value = "";
        }
    }

    const deleteItem = (itemName:string) => {
        const newFilteredArray = value.filter((item) => {
            return item!==itemName
        })
        onSave(name, [...newFilteredArray] );
    }


    return (
        <div className="flex flex-col w-full space-y-2">
        <div className="flex justify-between w-full">
                <div className="capitalize text-light">{name}</div>
                {!isEditing &&
                <div onClick={toggleIsEditing}><Icon name="pencil" /></div>
                }
                  {isEditing &&
             <div className="flex">
            <div className="text-green-500 " onClick={toggleIsEditing} ><Icon name="check_fill" /></div>
           </div>
           }
            </div>
            {!isEditing &&
                <div className="flex flex-col">
                   {value.toString()||`No ${name} yet`}
                </div>
            }
            {isEditing &&
                <>
                <div className="flex flex-wrap">
                    {value.map((v,i) => {
                        return <div onClick={()=>deleteItem(v) } className="flex space-x-2 items-center border px-2 rounded-lg hover:text-red-400">
                            <div className="" >{v}</div>
                            <div ><Icon name="close_fill"/></div>
                        </div>
                    })}
                       
            </div>
                  <div className="flex space-between space-x-2 items-center">
                  <input className="flex-1" ref={inputBoxRef} placeholder={`Add new ${name}`} />
                       <div onClick={()=>valueSave(name) }><Icon name="add_fill"/></div>
                </div>
                </>
            }

         </div>
    )
}


