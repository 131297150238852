import React, { useState} from 'react'
import { Tab } from "./Tab";
import { Icon } from "../Icons";
import {CandidatePreviewCard } from "./CandidatePreviewCard";
import { PassedCandidateCard } from "./PassedCandidateCard";
import {inviteCandidateByDocId } from "../../api/Business/CandidateAction";

interface InteractionBoxBusinessProps extends BaseInterface {
    show: boolean;
    onClose: () => void;
    user: User | null;
    subscribedCandidates: Array<any>;
    isAbsolute: boolean;
}



export const InteractionBoxBusiness:React.FC<InteractionBoxBusinessProps> = ({ show, onClose, user,subscribedCandidates, isAbsolute }) => {
    const [interactionBoxState, setInteractionBoxState] = useState<string>("interview");

    const onTabChange = (newTabName: string) => {
        setInteractionBoxState(() => newTabName);
    }
    const onInviteClick = (id: string) => {
       inviteCandidateByDocId({docId:id})
   }

    if (!show) return null;

    return (
        <div className={`${isAbsolute?"w-full ":"md:w-72 lg:w-80"} h-screen shadow-lg border-t flex flex-col bg-white z-50`}>
                    <div onClick={onClose} className="h-6 bg-blue-950 text-gray-100 text-lg px-1 "><Icon name="minus" /> </div>
                    <div className="px-2 h-10 flex space-x-2">
            <Tab onClick={onTabChange} name={"passed"} active={interactionBoxState === "passed"} frequency={subscribedCandidates.filter(candidate=>candidate?.is_denied === true).length} iconName="next_week" />
            <Tab onClick={onTabChange} name={"invited"} active={interactionBoxState === "invited"} frequency={subscribedCandidates.filter(candidate=>candidate?.is_invited === true && !candidate?.applied).length} iconName="bag_check" />
            <Tab onClick={onTabChange} name={"interview"} active={interactionBoxState === "interview"} frequency={ subscribedCandidates.filter(candidate=>candidate?.is_invited=== true && candidate?.applied===true).length   } iconName="video" />
            </div>
            <div className="h-7 border text-sm font-semibold px-2 capitalize text-secondary-accent flex items-center"><div>{interactionBoxState}</div></div>
          
        {interactionBoxState === "passed" && subscribedCandidates?.map((subscribedCandidate) => {
            if (subscribedCandidate?.is_denied === true) {
                return <PassedCandidateCard {...subscribedCandidate} onInviteClick={onInviteClick }  >ChatItem</PassedCandidateCard>
            }
            return null;
        })
        }
        {interactionBoxState === "invited" && subscribedCandidates?.map((subscribedCandidate) => {
            if (subscribedCandidate?.is_invited === true && !subscribedCandidate?.applied) {
                return <CandidatePreviewCard {...subscribedCandidate} >ChatItem</CandidatePreviewCard>
            }
            return null;
        })
        }
        {interactionBoxState === "interview" && subscribedCandidates?.map((subscribedCandidate) => {
            if (subscribedCandidate?.is_invited=== true && subscribedCandidate?.applied===true) {
                return <CandidatePreviewCard {...subscribedCandidate} >ChatItem</CandidatePreviewCard>
            }
            return null;
        })
        }
        </div>
    )
}
