import { useEffect, useState } from 'react'
import { db, businessesRef } from "../firebase";



export const useJobSubscribed = ({ uid }: { uid: string }) => {
    const [subscribedJobs, setSubscribedJobs] = useState<Array<any>>([])


    useEffect(() => {
        if (uid.trim() === "") {
            return;
        }
   
         const unsubscribe= db.collection("subscribed_jobs").where("jobseeker_id", "==", uid)
                .onSnapshot(querySnapshot => {
                    querySnapshot.docChanges().forEach(async (change:any) => {
                        if (change.type === "added") {
                            const jobListing = await db.collection("job_postings").doc(change.doc.data().job_posting_id).get();
                            const businessDetail = await businessesRef.doc(jobListing?.data()?.created_by).get();
                            setSubscribedJobs((current) => [...current, { ...change.doc.data(), ...jobListing.data(),...businessDetail.data(), id:change.doc.id }]);
                        }
                        if (change.type === "modified") {
                           
                            setSubscribedJobs(currentJobs => {
                                return currentJobs.map((jobPosting) => {
                                    if (jobPosting.job_posting_id ===change.doc.data().job_posting_id) {
                                        return { ...jobPosting,...change.doc.data() };
                                    }
                                    return jobPosting;
                                });
                            })
                        }
                        if (change.type === "removed") {
                            console.log("removed");
                        }
  
                    })
                })
                
             
                 return () => {
                    unsubscribe();
                 }
        

    }, [uid])

    console.log("subscribedJobs",subscribedJobs)

    return [subscribedJobs];

}