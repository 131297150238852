import React, {useState, useEffect} from 'react'
import { db} from "../../firebase";
import { Icon } from '../Icons';
import {SubmitSection } from "../Common";

interface SkillsProps extends BaseInterface{
    user: User | null;
    onSaveClick: () => void;
}

interface SkillScoreCardProps extends BaseInterface{
    skills: Array<any>;
    forJob?: string;
    onRadioButtonToggled: (e: React.ChangeEvent<HTMLInputElement>) => void;
}


const expertises: any = [
    { id:"0", name:"basic" },
    { id:"1", name:"limited experience" },
    { id:"2", name:"intermediate" },
    { id:"3", name: "advanced" },
    { id:"4", name:"expert" },
];


const SkillsForJobs:any=  [
   { id:"barber", skills: ["communication", "service"] },
{ id: "bartender", skills: ["service", "costumer_care", "communication"] },
{ id: "software_engineer", skills: ["technology", "algorithms", "communication"] },
{ id: "cashier", skills: ["service", "costumer_care", "communication"] },
]

interface DSkillsSchema{
    job_id: string;
    skill_id: string;
    score: number;
}

const SkillScoreCard: React.FC<SkillScoreCardProps> = ({ className, skills, onRadioButtonToggled, forJob }) => {
    


    return <div key={`${forJob}`} className=" shadow-sm rounded-lg flex overflow-x-auto my-6">
        {skills.map((item) => {
            return <div className="flex-none mx-2 shadow-lg rounded-lg">
                <div className="bg-lightest p-2">{item}</div>
                <div className="p-4">
                    {
                        expertises.map((i:any, id:any) => {
                            return <label className="flex items-center space-x-2">
                                <input onChange={onRadioButtonToggled} type="radio" id={`${forJob}-${item}-${id}`} name={`${forJob}-${item}`} />
                                <div>{ i.name}</div>
                            </label>
                        })
                    }
                </div>
              </div> 
       })}
    </div>
}


interface Job{
    job_id: string;

}


interface JobCardProps extends BaseInterface{
    isActive: boolean;
    onClick: () => void;
}

const JobCard: React.FC <JobCardProps>= ({children, isActive, onClick}) => {
    return <div onClick={onClick} className={`${isActive&&"bg-primary-dark text-primary-light opacity-100"} opacity-70 hover:opacity-100 border border-accent p-2 mx-2 my-2 rounded-lg flex items-center space-x-2 `}>
        <div>{children}</div>
        {isActive&&<div><Icon name="close_fill" /></div>}
    </div>
}






export const Skills:React.FC<SkillsProps> = ({className="", user, children, onSaveClick}) => {
    
    const [activeJob, setActiveJob] = useState<string|null>(null);
    const [profile, setProfile] = useState<Array<Job>>([]);
    const [skillData, setSkillData] = useState<Array<DSkillsSchema>>([]);



    useEffect(() => {
        if (profile.length <= 0) {
            return;
        }
        setActiveJob(profile[0].job_id);
   },[profile])



    useEffect(() => {
        const unsubscribe=db.collection("jobseekers").doc(user?.uid)
            .onSnapshot((querySnapshot) => {
                if (querySnapshot.data()?.profile) {
                    setProfile(() => querySnapshot.data()?.profile);
                }
                console.log(querySnapshot.data())
            })
        
        return () => {
            unsubscribe();
        }
    }, [user?.uid])


    useEffect(() => {
        const unsubscribe=db.collection("user_skill").doc(user?.uid)
            .onSnapshot((querySnapshot) => {
                if (querySnapshot.data()?.profile) {
                    setProfile(() => querySnapshot.data()?.profile);
                }
            })
        
        return () => {
            unsubscribe();
        }
    }, [user?.uid])



    const onJobCardClick = (jobId:string) => {
        setActiveJob(jobId);
    }


    

    const onRadioButtonToggled = (e: React.ChangeEvent<HTMLInputElement>) => {
       const parts:Array<string>= e.currentTarget.id.split("-");
       
        db.collection("user_skill").doc(`${user?.uid}_${parts[0]}_${parts[1]}`).set({
            jobseeker_id: user?.uid,
            job_id: parts[0],
            skill_id: parts[1],
            skill_score:parts[2],
        }).then((data) => {
           console.log("written")
        })
            .catch((err) => {
                console.log(err)
            })
    }


    return <div className="flex flex-col">
         <div className="flex flex-wrap  ">
           {profile.map((job, i) => {
            return <JobCard onClick={()=>onJobCardClick(job.job_id)} isActive={job.job_id===activeJob}  >{job.job_id }</JobCard>
          })}
        </div>
        <div className="">{
           SkillsForJobs.map((jobSkills: any, i: any) => {
                if (jobSkills.id !== activeJob) {
                    return null;
                }
                return <SkillScoreCard onRadioButtonToggled={onRadioButtonToggled} forJob={activeJob||"" } skills={jobSkills.skills }/>
          })
        }</div>
        <SubmitSection onSaveClick={onSaveClick}/>
        </div>
  
}
