import React from 'react'
import { Icon} from "../Icons";

interface RoleCheckerProps extends BaseInterface{
onHireTalentClick: () => void;
onApplyJobClick: () => void;
}

interface RoleSelectorProps extends BaseInterface{
    onClick: () => void;
}


const loginDivClick:React.MouseEventHandler<HTMLDivElement> = (e) => {
    e.stopPropagation();
}


const RoleSelector: React.FC<RoleSelectorProps> =({children, className, onClick}) =>{
    return <div onClick={onClick} className="flex flex-row justify-between w-full text-gray-600 hover:text-gray-900 cursor-pointer border-b pb-6">
           <div className="text-2xl sm:text-3xl lg:text-4xl leading-none font-extrabold  tracking-tight">{children}</div>
           <div><Icon name="right_arrow"/></div>
        </div>

}


const RoleChecker:React.FC<RoleCheckerProps> = ({className, children, onApplyJobClick, onHireTalentClick}) => {
    return (
        <div onClick={loginDivClick}  className="bg-primary  max-h-full overflow-y-scroll  flex flex-col relative mx-auto md:max-w-xl items-center rounded-lg pointer-events-auto z-40">
            <div className="text-xl font-semibold text-primary-accent tracking-tight py-4 border-b w-full text-center ">Tell us about your role...</div>
            <div className="py-10 px-8 w-full space-y-10">
            <RoleSelector onClick={onHireTalentClick}>Hire a Talent</RoleSelector>
            <RoleSelector onClick={onApplyJobClick}>Apply for Job</RoleSelector>
             </div>
        </div>
    )
}

export { RoleChecker }
