import React from 'react'
interface SmallHoverCardProps{

}

export const SmallHoverCard:React.FC<SmallHoverCardProps> = ({children}) => {
    return <div className="flex p-1 border rounded-lg hover:bg-gray-100 cursor-pointer">
        {children }
    </div>
}
