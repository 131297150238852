import {db } from "../../firebase";
import {useEffect , useState} from "react";

export const useSkillsByJobId =  ({ job_id }: { job_id: string }) => {
    const [skills, setSkills] = useState<Array<string>>([]);
    const [error, setError] = useState<any>();

    useEffect(() => {
     if (!job_id||job_id.trim()==="") {
         return;
     }
        (async () => {
            try {
                const jobDetails = await db.collection("jobs").doc(job_id).get();
                setSkills(jobDetails?.data()?.skills)
            }
            catch (err) {
                setError(err);
            }
        })()
     
   }, [job_id])
  
    return {skills, error}
  
}
