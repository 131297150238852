import React from 'react'
import { GoLinkExternal } from "react-icons/go";
import { CgMenu, CgSearch } from "react-icons/cg";
import { GrTwitter, GrLinkedin, GrClose, GrFormAdd , GrFormSubtract} from "react-icons/gr";
import { HiUserCircle, HiOutlineExclamationCircle, HiArrowRight, HiLocationMarker, HiLightningBolt, HiClock, HiInformationCircle, HiOfficeBuilding} from "react-icons/hi";
import { BiLogOut, BiLogIn,BiAddToQueue, BiStar, BiMinus, BiChevronRight, BiChevronLeft } from "react-icons/bi";
import { BsBriefcase, BsFillBriefcaseFill, BsFillPlusCircleFill,BsHeartFill } from "react-icons/bs";
import {RiSunFill, RiSunFoggyFill, RiMoonFill, RiMoonClearFill,RiAccountPinBoxFill } from "react-icons/ri";
import { AiFillCloseCircle, AiFillDollarCircle, AiFillStar, AiOutlineHeart,AiFillHome,AiFillCheckCircle, AiFillMinusCircle} from "react-icons/ai";
import { HiArrowCircleRight,HiOutlineLightBulb,HiOutlineLightningBolt,HiOutlinePencil, HiChevronDown,HiOutlineArrowRight } from "react-icons/hi";
import {FiMessageSquare } from "react-icons/fi";
import { MdVideocam,MdKeyboardArrowDown,MdVerifiedUser, MdRadioButtonChecked, MdRadioButtonUnchecked , MdNextWeek,MdLock} from "react-icons/md";
import { TiTick } from "react-icons/ti";
import { IoBagCheckSharp,IoAddCircleSharp } from "react-icons/io5";

export type IconNames = "external_link" | "hamburger_menu" | "twitter" | "linkedin" | "close" | "profile" | "logout" | "login" | "register" | "error" | "right_arrow" | "briefcase" | "location" | "lightning" | "clock" | "add" | "remove" | "star" | "night" | "evening" | "morning" | "afternoon" | "information" | "building" | "plus" | "close_fill" | "arrow_right" | "message" | "hamburger" | "search" | "minus" | "heart" | "briefcase_new" | "video"|"arrow_down"|"dollar"|"star_filled"|"heart_outline"|"chevron_right"|"tick"|"chevron_left"|"bulb"|"bolt"|"verified"|"radio_unchecked"|"radio_checked"|"next_week"|"bag_check"|"profile_setting"|"lock"|"home"|"pencil"|"check_fill"|"add_fill"|"minus_fill"|"chevron_down"|"arrow_right_primary";

 interface IconProps{
    name: IconNames;
    className?: string;
    hover?: boolean;
}


interface IconWrapperProps{
    className?: string;
}

const IconWrapper:React.FC<IconWrapperProps> = ({children,className}) => {
    return <div className={`${className} hover:bg-light transition duration-500 ease-out rounded-full p-1 `}>{ children}</div>
}

const IconSize: number = 25;

const Icon: React.FC<IconProps> = ({ name, className, hover }) => {
    if (name === "external_link") {
        return <GoLinkExternal className={` ${className} link-hover`}/>
    }
    if (name === "hamburger_menu") {
        return <IconWrapper><CgMenu size={IconSize} className={`${className} link-hover `}/></IconWrapper>
    }
    if (name === "hamburger") {
        return <CgMenu/>
    }
    if (name === "twitter") {
        return <GrTwitter className={`${className} link-hover`}/>
    }
    if (name === "linkedin") {
        return <GrLinkedin className={`${className} link-hover`}/>
    }
    if (name === "close") {
        return <IconWrapper><GrClose className={`${className} link-hover`} /></IconWrapper>
    }
    if (name === "profile") {
        if (hover) {
            return <IconWrapper><HiUserCircle size={IconSize+3}/></IconWrapper>;
        }
        return <HiUserCircle size={IconSize}/>
    }
    if (name === "logout") {
        return <BiLogOut size={IconSize}/>
    }
    if (name === "login") {
        return <BiLogIn size={IconSize}/>
    }
    if (name === "register") {
        return <BiAddToQueue size={IconSize}/>
    }
    if (name === "error") {
        return <HiOutlineExclamationCircle style={{paddingRight:"2px"}}/>
    }
    if (name === "right_arrow") {
        return <HiArrowRight size={ IconSize+13}/>
    }
    if (name==="briefcase") {
        return <BsFillBriefcaseFill/>
    }
    if (name === "location") {
        return <HiLocationMarker/>
    }
    if (name === "lightning") {
        return <HiLightningBolt/>
    }
    if (name === "clock") {
        return <HiClock/>
    }
    if (name === "add") {
        return <IconWrapper><GrFormAdd size="30px"/></IconWrapper>
    }
    if (name === "remove") {
        return <IconWrapper ><GrFormSubtract size="30px"/></IconWrapper>
    }
    if (name === "star") {
        return <BiStar/>
    }
    if (name === "night") {
        return <RiMoonClearFill/>
    }
    if (name === "evening") {
        return <RiMoonFill/>
    }
    if (name === "afternoon") {
        return <RiSunFill/>
    }
    if (name === "morning") {
        return <RiSunFoggyFill/>
    }
    if (name === "information") {
        return <HiInformationCircle/>
    }
    if (name === "building") {
        return <HiOfficeBuilding/>
    }
    if (name === "plus") {
        return <BsFillPlusCircleFill/>
    }
    if (name === "close_fill") {
        return <AiFillCloseCircle/>
    }
    if (name === "arrow_right") {
        return <HiArrowCircleRight/>
    }
    if (name === "message") {
        return <FiMessageSquare/>
    }
    if (name === "search") {
      return  <CgSearch/>
    }
    if (name === "minus") {
        return <BiMinus/>
    }
    if (name === "heart") {
        return <BsHeartFill/>
    }
    if (name === "briefcase_new") {
        return <BsBriefcase/>
    }
    if (name === "video") {
        return <MdVideocam/>
    }
    if (name === "arrow_down") {
        return <MdKeyboardArrowDown/>
    }
    if (name === "dollar") {
        return <AiFillDollarCircle/>
    }
    if (name === "star_filled") {
        return <AiFillStar/>
    }
    if (name === "heart_outline") {
        return <AiOutlineHeart/>
    }
    if (name === "chevron_right") {
        return <BiChevronRight/>
    }
    if (name === "chevron_left") {
        return <BiChevronLeft/>
    }
    if (name === "tick") {
        return <TiTick/>
    }
    if (name === "bulb") {
        return <HiOutlineLightBulb/>
    }
    if (name === "bolt") {
        return <HiOutlineLightningBolt/>
    }
    if (name === "verified") {
        return <MdVerifiedUser/>
    }
    if (name === "radio_checked") {
        return <MdRadioButtonChecked/>
    }
    if (name === "radio_unchecked") {
        return <MdRadioButtonUnchecked/>
    }
    if (name === "next_week") {
        return <MdNextWeek/>
    }
    if (name === "bag_check") {
        return <IoBagCheckSharp/>
    }
    if (name === "profile_setting") {
        return <RiAccountPinBoxFill/>
    }
    if (name === "lock") {
        return <MdLock/>
    }
    if (name === "home") {
        return <AiFillHome/>
    }
    if (name === "pencil") {
        return <HiOutlinePencil/>
    }
    if (name === "check_fill") {
        return <AiFillCheckCircle/>
    }
    if (name === "add_fill") {
        return <IoAddCircleSharp/>
    }
    if (name === "minus_fill") {
        return <AiFillMinusCircle/>
    }
    if (name === "chevron_down") {
        return <HiChevronDown/>
    }
    if (name === "arrow_right_primary") {
        return <HiOutlineArrowRight/>
    }
    else {
        return null;
    }
}




export {  Icon};
