import React, {useState, useEffect} from 'react'
import { SelectSearch } from "../SelectSearch";
import { fuzzySearch} from "../util/fuzzySearch";
import { Icon } from "../Icons";
import { db } from "../../firebase";
import {SubmitSection } from "../Common";
import { Error } from "../Common";

interface IndustryProps extends BaseInterface{
    user:  User | null;
    onComplete: () => void;
}

const options:Array<any> = [
    {name: 'Business', value: 'business'},
    { name: 'IT', value: 'it' },
    { name: "Banking", value: "banking" },
    { name: "Construction", value: "construction" },
    {name:"Education", value:"education"}
];


interface SmallJobCardProps extends BaseInterface{
    id: string;
    onClick: () => void;
}

const SmallJobCard: React.FC<SmallJobCardProps> = ({ children, onClick }) => {
    return <div onClick={onClick} className=" rounded-lg border px-2 py-1 mx-2 my-2 flex items-center space-x-2 hover:text-delete">
           <div> {children}</div>
           <div><Icon name="close_fill"/></div>
        </div>
}



export const Industry: React.FC<IndustryProps> = ({ onComplete , user}) => {
    const [onFocusIndustry, setOnFocusIndustry] = useState("");
    const [businessIndustries, setBusinessIndustries] = useState<Array<any>>([]);
    const [error, setError] = useState <string|null>(null);

    const onChangeSelect = (e:any) => {
        setOnFocusIndustry(e);
    }
    
    const removeIndustry = (industryId:string) => {
        const filteredProfile= businessIndustries.filter((item:any, i:any) => {
            return item.industry_id !== industryId;
        })
         db.collection("businesses").doc(user?.uid).update({
             industries:[...filteredProfile]
         })
    }

    const addNewIndustry = () => {
        if (onFocusIndustry.trim() === "") {
            return;
        }

        const index=options.findIndex((item) => item.value === onFocusIndustry);
        if (index === -1) {
            return;
        }
        const i = businessIndustries.findIndex((profile) => profile.industry_id === onFocusIndustry);
        if (i !== -1) {
            setError("Job already on the list");
            return;
        }
        db.collection("businesses").doc(user?.uid).update({
            industries: [...businessIndustries,{ industry_id: onFocusIndustry }]
        }).then((data => {
            setOnFocusIndustry("");
            setError(null);
        }))
            .catch((err) => {
                console.log(err);
        })
    }

    useEffect(() => {
        const unsubscribe=db.collection("businesses").doc(user?.uid)
            .onSnapshot((querySnapshot) => {
                if (querySnapshot.data()?.industries) {
                    setBusinessIndustries(()=>querySnapshot.data()?.industries)
                }
            })
        
        return () => {
            unsubscribe();
        }
    }, [user?.uid])


    const onSave = () => {
        if (businessIndustries.length <= 0) {
            setError("Please pick at least a job for us to match you with employee.")
            return;
        }
        onComplete();
    }
  
    return (
        <>
         {error && <Error>{ error}</Error>}
        <div className="h-full w-full border rounded-lg flex flex-col items-center">
            <div className="p-4 font-light text-secondary-dark text-center">Select the industry that best describes your company.</div>
            <div className="flex items-center justify-center space-x-2">
               <SelectSearch onChange={onChangeSelect} filterOptions={fuzzySearch} search options={options} value={onFocusIndustry} placeholder="Choose industries that matches your business." />
               <div onClick={addNewIndustry} className="text-2xl  text-primary-accent-light hover:text-primary-accent-light "><Icon name="plus"/></div>
            </div>
            <div className="flex flex-row justify-center flex-wrap">
            {
              businessIndustries &&  businessIndustries.map((item, i) => {
                  return <SmallJobCard onClick={()=>removeIndustry(item.industry_id)}  id={item.industry_id}>{item.industry_id}</SmallJobCard>
                })
            }
            </div>
            <SubmitSection onSaveClick={onSave } />

            </div>
            </>
    )
}
