import React, {useState, useEffect} from 'react'
import { Icon, IconNames} from "../Icons";

interface HeaderToggleProps extends BaseInterface{
    name: string;
    iconName: IconNames;
    completed?: boolean;
}


export const HeaderToggle: React.FC<HeaderToggleProps> = ({ className, children, name, iconName,completed }) => {
    const [isChildrenOn, setIsChildrenOn] = useState<boolean>(false);

    const toggleChildren = () => {
        setIsChildrenOn(current => !current);
    }
    
    useEffect(() => {
        if (!completed) {
            return;
        }
        toggleChildren();
    },[completed])


    return (
        <div onClick={toggleChildren} className={`${className} text-gray-600  text-opacity-90 hover:text-opacity-100 cursor-pointer px-primary  max-w-3xl  ${completed&&"text-green-600"}   ${isChildrenOn&&"text-opacity-100"} `}>
            <div className="text-lg py-2 font-extralight rounded-lg hover:bg-purple-50 leading-none  tracking-tight flex justify-between">
                <div>{name}</div>
                <div className="text-primary-accent"><Icon name={iconName}/></div>
            </div>
            {isChildrenOn && <div> {children}</div>}
        </div>
    )
}
