import  {useEffect, useState} from 'react'
import { db} from "../../firebase";

export const useSubscribedCandidates = ({ business_profile_id }: { business_profile_id: string | null }) => {
    const [deniedCandidates, setDeniedCandidates] = useState<Array<any>>([]);
    const [invitedCandidates, setInvitedCandidates] = useState<Array<any>>([]);


    useEffect(() => {
        if (!business_profile_id || business_profile_id.trim() === "") {
            return;
        }

        let unsub1 = () => { };
        let unsub2 = () => { };

        (async () => {
            const allJobPostings = await db.collection("job_postings").where("created_by", "==", business_profile_id).get()
                
            allJobPostings.forEach(async (doc) => {
                // setSubscribedCandidates((current) => [...current, { job_listing_id: doc.id }])
                unsub1 = db.collection("subscribed_jobs").where("job_posting_id", "==", doc.id).where("is_denied", "==", true)
                    .onSnapshot((querySnapshot => {
                        querySnapshot.docChanges().forEach(async(change: any) => {
                            if (change.type === "added") {
                                const jobSeekerData= await db.collection("jobseekers").doc(change.doc.data().jobseeker_id).get();
                                setDeniedCandidates((current) => [...current, { ...change.doc.data(), ...jobSeekerData.data(), id:change.doc.id }])
                            }
                            if (change.type === "modified") {
                                setDeniedCandidates((current) => current.map(i => {
                                    if (i.id === change.doc.id) {
                                        return {...i,...change.doc.data()}
                                    }
                                    return i;
                                }))
                            }
                            if (change.type === "removed") {
                                setDeniedCandidates((current) => current.filter(i=>i.id!==change.doc.id))
                            }
                     })
                    }));
              
                unsub2 = db.collection("subscribed_jobs").where("job_posting_id", "==", doc.id).where("is_invited", "==", true)
                    .onSnapshot((querySnapshot) => {
                        querySnapshot.docChanges().forEach(async(change: any) => {
                            if (change.type === "added") {
                                const jobSeekerData= await db.collection("jobseekers").doc(change.doc.data().jobseeker_id).get();
                                setInvitedCandidates((current) => [...current, { ...change.doc.data(), ...jobSeekerData.data(), id:change.doc.id }])
                            }
                            if (change.type === "modified") {
                                setInvitedCandidates((current) => current.map(i => {
                                    if (i.id === change.doc.id) {
                                        return {...i,...change.doc.data()}
                                    }
                                    return i; 
                                }))
                            }
                            if (change.type === "removed") {
                                setInvitedCandidates((current) => current.filter(i=>i.id!==change.doc.id))
                            }
                        })
                    });
            
            })
        })();
    
        return () =>{
            unsub1();
            unsub2();
        }
        

    }, [business_profile_id])

    
    return { subscribedCandidates:[...deniedCandidates,...invitedCandidates] };
}
