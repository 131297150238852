import React, {useRef, useEffect} from 'react'
import {capitalCase } from "change-case";

interface EditableStringFieldProps{
    focus?: boolean;
    name: string;
    value: string;
    onChange: (name:string, value:string) => void;
}

export const EditableStringField: React.FC<EditableStringFieldProps> = ({name, focus, value, onChange }) => {
    
    const inputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (!inputRef.current) {
            return;
        }
        if (!focus) {
            return;
        }
        inputRef.current.focus();
    }, [focus])


    const onInputValueChange = (e:any) => {
        onChange(name,e.target.value);
    }

    return (
        <div className="flex-1 ">
            <input ref={inputRef} type="text" className="w-full outline-none focus:border-2 focus:border-red-800  " placeholder={capitalCase(name)} value={value} onChange={onInputValueChange} />
            </div>
    )
}

