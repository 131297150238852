import React from 'react'
import { CSSTransition } from "react-transition-group";
import { Overlay} from "../Overlay";
import "./SideNav.css";
import { Icon} from "../Icons";
import { SideNavMenuItem } from './SideNavMenuItem';
import { SideNavMenuCategory } from "./SideNavMenuCategory";
import {useProfileUrl } from "../../api/useProfileUrl";
import { useHistory} from "react-router-dom";

interface SideNavProps{
    user: User | null;
    show: boolean;
    onClose: () => void;
    onViewProfileClick: () => void;
    onJobPreferenceClick: () => void;
    onLogOutClick: () => void;
    onSideBarToggle: () => void;
    isJobSeekerSideNav: boolean;
}


export const SideNav:React.FC<SideNavProps> = ({ show, onClose, user, onJobPreferenceClick,onViewProfileClick, isJobSeekerSideNav, onLogOutClick, onSideBarToggle, children}) => {
    const {photoUrl}= useProfileUrl();
    const history=useHistory();

    const viewProfileClick = () => {
        onSideBarToggle();
        onViewProfileClick();
    }
    
    const jobPreferenceClick = () => {
        onSideBarToggle();
        onJobPreferenceClick();
   }


    return (
        <>
        <CSSTransition
            in={show}
            timeout={400}
            classNames="side-menu"
            unmountOnExit
        >
                <div className="absolute top-0 left-0 shadow-lg z-50">
                    <div className="w-72 sm:w-80 bg-gray-200 overflow-y-hidden h-screen z-30 flex flex-col tracking-tight">
                        <div className="flex bg-primary justify-between p-4 items-center">
                           <div className="rounded-full bg-gray-100  h-8 w-8 overflow-hidden flex items-center">
                            <img alt="" className="object-contain" src={photoUrl}/>
                           </div>
                            <div className="flex flex-col ">
                                <div className="capitalize">{user?.displayName}</div>
                                <div className="text-primary-accent text-sm font-semibold flex space-x-2">
                                    {isJobSeekerSideNav && <>
                                        <span className="cursor-pointer" onClick={viewProfileClick}>View Profile</span>
                                    <span>·</span>
                                        <span className="cursor-pointer" onClick={jobPreferenceClick}>Job Preference</span>
                                    </>}
                                    {!isJobSeekerSideNav && <>
                                        <span className="cursor-pointer" onClick={onViewProfileClick}>View Company Profile</span>
                                    </>}
                                </div>
                            </div>
                            <div onClick={ onClose} className=""><Icon name="close"/></div>
                        </div>
                        <div className="flex justify-between px-4 py-3 ">
                            <div className="">{isJobSeekerSideNav?"Jobseeker Account":"Business Account"}</div>
                            <div className="underline cursor-pointer" onClick={isJobSeekerSideNav?()=>history.push("/business"):()=>history.push("/jobSeeker")}>{isJobSeekerSideNav?"Switch to Business":"Switch to Jobseeker"}</div>
                        </div>
                        {children}
                        <div onClick={onLogOutClick } className="p-4 underline">
                            Logout
                        </div>
                </div>
            </div>
        </CSSTransition>
        <Overlay show={show} onClick={onClose} />
        </>

    )
}

export {SideNavMenuCategory, SideNavMenuItem}