
import { Icon, IconNames} from "../../components/Icons";

interface ActionButtonJobListingProps {
    iconName: IconNames;
    primary: boolean;
    name: string;
    onClick: () => void;
}


export const ActionButtonJobListing: React.FC<ActionButtonJobListingProps> = ({ iconName, primary, name, onClick }) => {

    return <div onClick={onClick} className={`${primary ? "bg-primary-dark text-primary-light" : "text-primary-accent bg-gray-200 "} flex-1 border  h-full rounded-lg flex items-center space-x-1 justify-center cursor-pointer hover:opacity-90 shadow-lg `}>
        <div className=""><Icon name={iconName} /></div>
        <div className="text-xs">{name}</div>
    </div>
}