import React, {useEffect, useState, Children} from 'react'
import {Location } from "../components/Location";
import {useHistory} from "react-router-dom";
import { db} from "../firebase";
import {HeaderToggle } from "../components/Common/HeaderToggle";
import { HeaderToggleContent } from '../components/Common';
import { BusinessInfo,Industry  } from "../components/HireOnboarding";
import SubmitApply from '../components/Apply/SubmitApply';


interface HireProps extends BaseInterface{
    isJobSeeker: boolean | null;
    user: User|null;
}

export const Hire: React.FC<HireProps> = ({ className,isJobSeeker,user, children }) => {
    const history = useHistory();
    const [isLocationDone, setIsLocationDone] = useState<boolean>(false);
    const [isBusinessInfoFormDone, setIsBusinessInfoFormDone] = useState<boolean >(false);
    const [isIndustryFormDone, setIsIndustryFormDone] = useState<boolean >(false);

    useEffect(() => {
        if (!user?.uid) {
            return;
        }
        db.collection("businesses").doc(user?.uid).get()
            .then((data) => {
                if (data.exists) {
                    return;
                }
                else {
                    db.collection("businesses").doc(user?.uid).set({});
                }
            })
            .catch((err) => {
                console.log(err);
             })
    },[user?.uid])

    useEffect(() => {
        if (!user?.email) {
            history.push("/");
            return;
        }
        if (isJobSeeker === null) {
            history.push("/");
            return;
        }
        if (isJobSeeker) {
            history.push("/apply");
            return;
        }
        return;
    }, [user, isJobSeeker, history])

    
   const onSaveClickLocation = () => {
       setIsLocationDone(true);
   }

    const onSaveClickBusiness = () => {
    setIsBusinessInfoFormDone(true);
    }

    const onSaveClickIndustry = () => {
    setIsIndustryFormDone(true);
    }



    return (
        <div className={`${className}  min-h-screen`}>
            {Children.toArray(children)[0]}
            <div className="pt-16 h-screen  w-full flex flex-col space-y-6">
            <HeaderToggle completed={isLocationDone} iconName="location" name="Location">
                <HeaderToggleContent onSaveClick={onSaveClickLocation}>
                    <Location isBusiness={true} user={user} onSaveClick={onSaveClickLocation} />
                </HeaderToggleContent>
            </HeaderToggle>
            <HeaderToggle completed={isBusinessInfoFormDone} iconName="information" name="Business info">
                <HeaderToggleContent onSaveClick={onSaveClickBusiness}>
                    <BusinessInfo user={user} onComplete={onSaveClickBusiness} />
                </HeaderToggleContent>
            </HeaderToggle>
            <HeaderToggle completed={isIndustryFormDone} iconName="building" name="Industry">
                <HeaderToggleContent onSaveClick={onSaveClickIndustry}>
                    <Industry user={user} onComplete={onSaveClickIndustry} />
                </HeaderToggleContent>
            </HeaderToggle>
                <SubmitApply onClick={()=>history.push("/business")}  active={isLocationDone && isBusinessInfoFormDone && isIndustryFormDone} />
                </div>

        </div>
    )
}
