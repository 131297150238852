import React from 'react'
import {Icon } from "../Icons";

interface SubmitApplyProps extends BaseInterface{
    active: boolean;
    onClick: () => void;
}


const SubmitApply: React.FC<SubmitApplyProps> = ({ children, active, onClick }) => {
   

    const activeButtonStyle = "w-auto flex-none bg-primary-dark hover:bg-purple-700 text-white text-lg leading-6 font-semibold py-3 px-20 border border-transparent rounded-md focus:ring-2 focus:ring-offset-2 focus:ring-offset-white focus:ring-purple-900 focus:outline-none transition-colors duration-200 shadow-2xl flex  items-center space-x-2";
    const disabledButtonStyle = "w-auto flex-none bg-gray-100 hover:bg-gray-200 text-gray-400 text-lg leading-6 font-semibold py-3 px-20 border border-transparent rounded-md focus:ring-2 focus:ring-offset-2 focus:ring-offset-white focus:ring-purple-900 focus:outline-none transition-colors duration-200 shadow-2xl flex  items-center space-x-2 cursor-not-allowed ";

    return (
        <div className="px-4 max-w-2xl flex justify-center items-center  ">
            <button onClick={() => { active && onClick() }} className={active?activeButtonStyle:disabledButtonStyle }>
                <div>{children }</div>
                <Icon name="arrow_right" />
            </button>
        </div>
    )
}

export default SubmitApply
