import { db, firebase } from "../../firebase";

interface ActionArgs {
    job_listing_id: string;
    jobseeker_id: string;
}

export const passCandidate = async ({ job_listing_id, jobseeker_id }: ActionArgs) => {
    //Logic
    try {
        await db.collection("subscribed_jobs").doc(`${job_listing_id}_${jobseeker_id}`).set({
            job_posting_id:job_listing_id,
            jobseeker_id,
            is_denied: true,
            is_invited: false,
            time_denied:firebase.firestore.FieldValue.serverTimestamp(),
        },{merge:true});
        return true;
    }
    catch (err) {
        console.log(err);
        return false;
    }

}

export const inviteCandidate = async ({ job_listing_id,jobseeker_id  }: ActionArgs) => {
    try {
        await db.collection("subscribed_jobs").doc(`${job_listing_id}_${jobseeker_id}`).set({
            job_posting_id:job_listing_id,
            jobseeker_id,
            is_invited:true,
            is_denied: false,
            time_invited:firebase.firestore.FieldValue.serverTimestamp(),
        }, { merge: true });
        return true;
    }
    catch (err) {
        return false;
    }

}

export const inviteCandidateByDocId = async ({ docId }: {docId: string}) => {
    try {
        await db.collection("subscribed_jobs").doc(docId).update({
            is_invited:true,
            is_denied: false,
            time_invited:firebase.firestore.FieldValue.serverTimestamp(),
        });
        return true;
    }
    catch (err) {
        return false;
    }

}

