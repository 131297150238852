interface SoftSkillSpanProps{
    active?: boolean;
    onClick: () => void;
    unicode: string;
    name: string;
}


export const SoftSkillSpan: React.FC<SoftSkillSpanProps> = ({ onClick, active, unicode, name }) => {
    if (!name || name.trim() === "") return null;

    return <button onClick={onClick} className={`${active&&"bg-purple-200 "} flex items-center px-2 py-1 border rounded-lg space-x-1  capitalize opacity-90 hover:opacity-100`}>
        <div >{ unicode}</div>
        <div >{name}</div>
      </button>
}