import './Or.css';


interface OrProps extends BaseInterface{

}

const Or: React.FC<OrProps> = ({className}) => {
    return <div className=" w-full text-center text-sm text-secondary-dark">
        <div className={`${className} relative or-class`}>or</div>
        </div>
}

export{Or}