import React from 'react'

interface FormLabelProps extends BaseInterface{
    extraInfo?: string;
    required?: boolean;
    error?: boolean;
    errorMsg?: string;
}

export const FormLabel:React.FC<FormLabelProps> = ({children, extraInfo, required, error, errorMsg}) => {
    return (
        <div className=" flex space-x-1 items-center">
            <div className="font-semibold">{children}</div>
            {extraInfo && <div className="">{`(${extraInfo})`}</div>}
            {required && !error && <div className="text-xs text-gray-400 capitalize">*</div>}
            {required && error && <div className="text-xs text-red-400 capitalize">*{errorMsg??"Required"}</div>}
        </div> 
    )
}
