import { db, firebase } from '../../firebase';

export const addSoftSkills = async({user_id, soft_skill_id}:{user_id:string, soft_skill_id:string}) => {
            try {
               await db.collection("jobseekers").doc(user_id).update({ soft_skills: firebase.firestore.FieldValue.arrayUnion(soft_skill_id) });
            }
            catch (err) {
                console.log(err);
            }
}
