import React from 'react'
interface ContactProps{
    className?: string;
}

const Contact:React.FC<ContactProps> = ({className}) => {
    return (
        <div className={`${className} h-screen px-primary bg-gray-900 text-primary-light `}>
            Contact
        </div>
    )
}

export { Contact }
