import  {useState, useEffect, useReducer} from 'react';
import {BrowserRouter as Router, Switch, Route} from "react-router-dom";
import { Home } from "./pages/Home";
import { Contact } from "./pages/Contact";
import { PageNotFound } from './pages/PageNotFound';
import { Apply } from "./pages/Apply";
import {Hire } from "./pages/Hire";
import { Nav } from "./components/Nav";
import {Footer } from "./components/Footer";
import {Modal, ModalCloseButton } from "./components/Common";
import { Login } from "./components/Forms/Login";
import { RoleChecker } from './components/Forms/RoleChecker';
import { auth } from "./firebase";
import { JobSeeker } from './pages/JobSeeker';
import {Business } from "./pages/Business";
import { ButtonSubmitPrimary } from './components/Common/ButtonSubmitPrimary';
import { Icon} from "./components/Icons";

const SampleUser1: User = {
  uid:"1212",
  email: "pawanbhatta@bluebird.co",
  displayName:"Pawan Bhatta",
  jobPostings: [],
  jobs: [ {name:"Bartender", skills:["Communication"]}]
}

const Users: Array<User> = [SampleUser1];

type UserAction =
   | { type: 'log_out' }
   | { type: 'log_in', user:User }
  | { type: "register", user: User }


const userReducer = (state: User|null, action: UserAction):User|null  => {
  switch (action.type) {
    case "log_in":
      return action.user;
    case "log_out":
      auth.signOut().then(() => {
        return {};
      }).catch((error) => {
        return state;
      })
      return null;
    
    case "register":
      Users.push(action.user);
      return action.user;
  }
}


function App() {
  const [navHeight] = useState<number>(12);
  const [isJobSeeker, setIsJobSeeker] = useState<boolean | null>(null);
  const [isModalOn, setIsModalOn] = useState<boolean>(false);
  const [isRoleModalOn, setIsRoleModal] = useState<boolean>(false);
  const [loginFormState, setLoginFormState] = useState<null | string>(null);
  const [user, dispatchUser] = useReducer(userReducer,null);


  const closeModal = () => {
     setIsModalOn(()=>false)
 }
  
  const openModal = () => {
      setIsModalOn(()=>true)
  }

  const closeRoleModal = () => {
    setIsRoleModal(false);
  }

  const openRoleModal = () => {
    setIsRoleModal(true);
  }

  const onRegisterClick = () => {
    setLoginFormState("register");
    openModal();
  }

  const onLoginClick = () => {
    setLoginFormState("login");
    openModal();
  }

  const onApplyJobClick = (): void => {
    if (!user?.uid) {
      setIsModalOn(true);
    }
    setIsRoleModal(false);
    setIsJobSeeker(() => true);
}

  const onHireTalentClick = (): void => {
    if (!user?.uid) {
      setIsModalOn(true);
    }
    setIsRoleModal(false);
  setIsJobSeeker(() => false);
  }
  

  const onLogin = (user:User): void => {
    dispatchUser({ type: "log_in", user });
  }
   
  const onRegister = (user:User): void => {
    dispatchUser({type:"register", user})
  }


  const onLogOut = (): void => {
    dispatchUser({ type: "log_out" });
    setIsModalOn(false);
  }

  //runs once login is done and we are sure user is applying for job
  useEffect(() => {
    console.log(user);
    if (!user?.email) {
      return;
    }
    if (isJobSeeker === null) {
      console.log("not set up job seeker or hirer");
      setIsRoleModal(true);
      return;
    }
   
  },[isJobSeeker,user])


  useEffect(() => {
  
    auth.onAuthStateChanged((user) => {
      if (user) {
        if (user.email) {
          dispatchUser({type:"log_in",user:{uid:user.uid,email:user.email, displayName:(user.displayName||""), photoUrl:(user.photoURL||"")}});
        }
      }
      else {
        console.log("no user");
      }
   })
  },[])


  return (
    <div className="flex flex-col">
      <Router>
          <Switch>
            <Route exact path="/">
              <Home isJobSeeker={isJobSeeker} user={user} onApplyJobClick={onApplyJobClick} onHireTalentClick={onHireTalentClick}>
              <Nav onLoginClick={onLoginClick} onLogOut={onLogOut} className={` h-12`} onRegisterClick={onRegisterClick} user={user} />
              <div className="mt-16 h-10 bg-gray-200 px-primary flex items-center gap-4 text-sm">
                <div>Looking for job within your city?</div>
                <ButtonSubmitPrimary className="py-0.5 uppercase flex gap-1" active={true} onClick={onHireTalentClick}>
                 <div> Apply</div>
                <div className="text-xs"><Icon name="arrow_right_primary"/></div> 
                </ButtonSubmitPrimary>
              </div>
                <Footer />
              <Modal show={isModalOn && !!!user?.email} onModalClose={closeModal}>
               <Login state={loginFormState}  onLogin={onLogin} onRegister={onRegister}>
                 <ModalCloseButton onClose={closeModal}/>
              </Login>
              </Modal>
               <Modal show={isRoleModalOn} onModalClose={closeRoleModal}>
               <RoleChecker onHireTalentClick={onHireTalentClick} onApplyJobClick={onApplyJobClick} />
              <ModalCloseButton onClose={closeRoleModal}/>
              </Modal>
              </Home>
            </Route>
            <Route exact path="/contact">
              <Contact className={`pt-12`}/>
          </Route>
          <Route exact path="/apply">
            <Apply isJobSeeker={isJobSeeker} user={user} >
              <Nav onLoginClick={onLoginClick} onLogOut={onLogOut} className={` h-12`} onRegisterClick={onRegisterClick} user={user} />
            </Apply>
          </Route>
          <Route exact path="/hire">
            <Hire isJobSeeker={isJobSeeker} user={user} >
            <Nav onLoginClick={onLoginClick} onLogOut={onLogOut} className={` h-12`} onRegisterClick={onRegisterClick} user={user} />
            </Hire>
          </Route>
          <Route exact path="/jobSeeker">
            <JobSeeker onLogOutClick={onLogOut} user={user}>
            </JobSeeker>
          </Route>
          <Route exact path="/business">
            <Business onLogOutClick={onLogOut} user={user} />
          </Route>
            <Route path="*">
            <PageNotFound className={`pt-${navHeight}`} >
               <Nav onLoginClick={onLoginClick} onLogOut={onLogOut} className={` h-12`} onRegisterClick={onRegisterClick} user={user} />
               <Footer />
             </PageNotFound>
            </Route>
          </Switch>
      </Router>
     
    </div>
  );
}

export default App;
