import React from 'react'
import { Icon, IconNames} from "../Icons";

interface SideNavMenuItemProps extends BaseInterface{
    name: string;
    subTitle: string;
    iconName: IconNames;
    onClick: () => void;
}

export const SideNavMenuItem:React.FC<SideNavMenuItemProps> = ({iconName,name,subTitle, onClick}) => {
    return (
        <div onClick={onClick} className="border bg-primary flex space-x-4 px-4 py-3">
            <div className="flex items-center text-light text-2xl"><Icon name={iconName }/></div>
            <div className="flex flex-col">
                <div className="font-semibold">{ name}</div>
                <div className="text-xs text-light">{ subTitle}</div>
            </div>
        </div>
    )
}
