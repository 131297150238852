import React from 'react'
import {Icon } from "../Icons";

interface SkillLevelCardProps{
    skill_id: string;
    skill_score: number;
}



const SkillDictionary: { [key:number]:string}={
    0:"Basic Knowledge",
    1:"Limited Experience",
    2:"Intermediate",
    3:"Advanced",
    4:"Expert"
}


export const SkillLevelCard:React.FC<SkillLevelCardProps> = ({skill_id,skill_score}) => {
    return (
        <div key={skill_id } className="flex-none rounded-lg shadow-lg max-w-sm flex flex-col">
            <div className="bg-gray-200 capitalize py-3 px-2 rounded-t-lg rounded-b-none">{skill_id}</div>
           <div className="p-2"> {[0, 1, 2, 3, 4].map((item, i) => {
                return <div className="flex items-center space-x-2"> 
                     <div>{
                     skill_score===item ? <Icon name="radio_checked"/>:<Icon name="radio_unchecked"/>
                    }</div>
                    <div>{SkillDictionary[item] }</div>
                 </div>
           })}
                </div>
        </div>
    )
}
