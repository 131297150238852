import React from 'react'
import { removeSoftSkills } from '../../api/JobSeekerActions/removeSoftSkill';
import { addSoftSkills } from '../../api/JobSeekerActions/useAddSoftSkills';
import { SoftSkillSpan} from "./SoftSkillSpan";

interface SoftSkillsEditProps{
    activeSoftSkills: Array<string>;
    allSkills: Array<any>;
    user?: User | null;
}

const SoftSkillsEdit: React.FC<SoftSkillsEditProps> = ({ activeSoftSkills, allSkills, user }) => {
    const addRemoveSoftSkill = (softSkillId:string) => {
        if (activeSoftSkills.includes(softSkillId)) {
            removeSoftSkills({soft_skill_id:softSkillId, user_id:user?.uid??""})
            return;
        }
        addSoftSkills({soft_skill_id:softSkillId, user_id:user?.uid??""});
   }

    return (
        <div className="mt-2">
        <div className="flex flex-wrap items-center  gap-2 pt-2 pb-4">
        {
                allSkills?.map((softSkill) => {
                    return <SoftSkillSpan key={softSkill.id} name={softSkill.name} onClick={() =>addRemoveSoftSkill(softSkill.id) } unicode={softSkill.unicode} active={activeSoftSkills.includes(softSkill.id)}/>
                    })
             }
        </div>
      </div>
    )
}

export default SoftSkillsEdit
