import React, {useEffect, useState, Children} from 'react'
import {useHistory} from "react-router-dom";
import { HeaderToggle } from '../components/Common/HeaderToggle';
import { Location} from "../components/Location";
import {Skills,Jobs, Availability } from "../components/Apply";
import { HeaderToggleContent } from '../components/Common/HeaderToggleContent';
import { db } from "../firebase";
import SubmitApply from '../components/Apply/SubmitApply';


interface ApplyProps extends BaseInterface{
    isJobSeeker: boolean|null;
    user: User|null;    
}


export const Apply: React.FC<ApplyProps> = ({ className, isJobSeeker, user, children }) => {
    const history=useHistory();
    const [isLocationDone, setIsLocationDone] = useState<boolean>(false);
    const [isJobSelectionDone, setIsJobSelectionDone] = useState<boolean>(false);
    const [isSkillsDone, setIsSkillsDone] = useState(false);
    const [isAvailablityDone, setIsAvailablityDone] = useState(false);



    useEffect(() => {
        if (!user?.email) {
            history.push("/");
            return;
        }
        if (isJobSeeker === null) {
            history.push("/");
            return;
        }
        if (!isJobSeeker) {
            history.push("/hire"); 
        }
        return;
    }, [user, isJobSeeker, history])
 

    useEffect(() => {
        if (!user?.uid) {
            return;
        }
        db.collection("jobseekers").doc(user?.uid).get()
            .then((data) => {
                if (data.exists) {
                    return;
                }
                else {
                    db.collection("jobseekers").doc(user?.uid).set({});
                }
            })
            .catch((err) => {
                console.log(err);
             })
    },[user?.uid])
    

  

    const onSaveClickLocation = () => {
        setIsLocationDone(true);
    }

    const onSaveClickJobs = () => {
        setIsJobSelectionDone(true);
    }
    const onSaveClickSkills = () => {
        setIsSkillsDone(true);
    }

    const onSaveClickAvailability = () => {
        setIsAvailablityDone(true);
    }

    return (
        <div className={`${className} border  h-screen`}>
            {Children.toArray(children)[0]}
            <div className="pt-16 h-screen  w-full flex flex-col space-y-6">
            <HeaderToggle completed={isLocationDone} iconName="location" name="Location">
                <HeaderToggleContent onSaveClick={onSaveClickLocation} >
                    <Location isBusiness={false} user={user} onSaveClick={onSaveClickLocation }>
                    </Location>
                </HeaderToggleContent>
            </HeaderToggle>
            <HeaderToggle completed={isJobSelectionDone} iconName="briefcase" name="Your Job">
                <HeaderToggleContent onSaveClick={ onSaveClickJobs}>
                    <Jobs onComplete={onSaveClickJobs} user={user} >
                    </Jobs>
                </HeaderToggleContent>
            </HeaderToggle>
            <HeaderToggle completed={isSkillsDone} iconName="lightning" name="Skills">
                 <HeaderToggleContent onSaveClick={onSaveClickSkills}>
                    <Skills onSaveClick={onSaveClickSkills} user={user}>
                    </Skills>
                </HeaderToggleContent>
            </HeaderToggle>
            <HeaderToggle completed={isAvailablityDone} iconName="clock" name="Availability">
               <HeaderToggleContent onSaveClick={onSaveClickAvailability}>
                    <Availability onSaveClick={onSaveClickAvailability} user={user} >
                    </Availability>
                </HeaderToggleContent>
            </HeaderToggle>
                <SubmitApply onClick={()=>history.push("/jobSeeker")} active={isLocationDone && isAvailablityDone && isSkillsDone && isJobSelectionDone} />
                </div>
        </div>
    )
}
