import React from 'react'

interface SectionLabelProps{
    name: string;
 }

export const SectionLabel:React.FC<SectionLabelProps> = ({name}) => {
    return (
        <div className="text-light font-semibold ">
            {name}
        </div>
    )
}
