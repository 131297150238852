import React, {useState, useEffect} from 'react'
import { Icon } from "../Icons";
import { db} from "../../firebase";
import { SelectSearch } from "../SelectSearch";
import { fuzzySearch } from "../util/fuzzySearch";
import {SubmitSection } from "../Common";
import { Error} from "../Common";

interface JobsProps extends BaseInterface{
    user: User | null;
    onComplete: () => void;
}


const jobs: Array<Job> = [
    {name:"Bartender", skills:["Communication"]},
    {name: "Barber", skills: ["Communication","Service","Customer Care"] },
    { name: "Software Engineer", skills: ["Decision Making", "Technology"] },
    { name: "Cashier", skills: ["Communication"] },
]

const options:Array<any> = [
    {name: 'Bartender', value: 'bartender'},
    { name: 'Barber', value: 'barber' },
    { name: "Software Engineer", value: "software_engineer" },
    { name: "Cashier", value: "cashier" },
    {name:"Manager", value:"manager"}
];



interface SkillScore {
    [key: string]: number;
};

export interface job{
    job_id: string;
    skill_level:Array<SkillScore>
}




interface SmallJobCardProps extends BaseInterface{
    id: string;
    onClick: () => void;
}

const SmallJobCard: React.FC<SmallJobCardProps> = ({ children, onClick }) => {
    return <div onClick={onClick} className=" rounded-lg border px-2 py-1 mx-2 my-2 flex items-center space-x-2 hover:text-delete">
           <div> {children}</div>
           <div><Icon name="close_fill"/></div>
        </div>
}



export const Jobs:React.FC<JobsProps> = ({className="", user, onComplete, children}) => {

    const [onFocusJob, setOnFocusJob] = useState<string>("");
    
    const [profile, setProfile] = useState<Array<job>>([]);

    const [error, setError] = useState<string|null>(null);


    const onChange = (e: React.FormEvent<HTMLInputElement>): void => {
        setOnFocusJob(e.currentTarget.value);
    }
    
    const addNewJob = () => {
        if (onFocusJob.trim() === "") {
            return;
        }
        const index=options.findIndex((item) => item.value === onFocusJob);
        if (index === -1) {
            return;
        }
        const i = profile.findIndex((job) => job.job_id === onFocusJob);
        if (i !== -1) {
            setError("Job already on the list");
            return;
        }
        db.collection("jobseekers").doc(user?.uid).update({
            profile: [...profile,{ job_id: onFocusJob }]
        }).then((data => {
            setOnFocusJob("");
        }))
            .catch((err) => {
                console.log(err);
        })
    }
    

    const removeJob = (id:string) => {
       const filteredProfile= profile.filter((item:any, i:any) => {
           return item.job_id !== id;
       })
        db.collection("jobseekers").doc(user?.uid).update({
            profile:[...filteredProfile]
        })
    }

    useEffect(() => {
        const unsubscribe=db.collection("jobseekers").doc(user?.uid)
            .onSnapshot((querySnapshot) => {
                if (querySnapshot.data()?.profile) {
                    setProfile(()=>querySnapshot.data()?.profile)
                }
                console.log(querySnapshot.data())
            })
        
        return () => {
            unsubscribe();
        }
    }, [user?.uid])

  

    const onChangeSelect = (e:any) => {
        setOnFocusJob(e);
    }
     
    const onSave = () => {
        if (profile.length <= 0) {
            setError("Please pick at least a job for us to match you with employee.")
            return;
        }
        onComplete();
    }

    return <>
        {error && <Error>{ error}</Error>}
        <div className="flex flex-col space-y-8 py-8">
        <div className="flex items-center justify-center space-x-2">
          <SelectSearch onChange={onChangeSelect} filterOptions={fuzzySearch} search options={options} value={onFocusJob} placeholder="Choose job that interests you" />
           <div onClick={addNewJob} className="text-2xl  text-primary-accent-light hover:text-primary-accent-light "><Icon name="plus"/></div>
        </div>
        <div className="flex flex-row justify-center flex-wrap">
            {
              profile &&  profile.map((item, i) => {
                  return <SmallJobCard onClick={()=>removeJob(item.job_id)}  id={item.job_id}>{item.job_id}</SmallJobCard>
                })
            }
        </div>
        <SubmitSection onSaveClick={onSave } />
    </div>
        </>
}