import React,{useState} from 'react'
import { useGetAllSoftSkills } from '../../api/General/useGetAllSoftSkills';
import {Icon } from "../Icons";
import { Modal } from './Modal';
import SoftSkillsEdit from './SoftSkillsEdit';
import {SoftSkillSpan } from "./SoftSkillSpan";

interface SoftSkillsProps{
    value: Array<string>;
    user?: User|null;

}


export const SoftSkills: React.FC<SoftSkillsProps> = ({value, user}) => {
    const {softSkills} = useGetAllSoftSkills();
    const [isEditModalOpen, setIsEditModalOpen] = useState(false)

    const closeModal=()=> {
      setIsEditModalOpen(false)
    }
  
    const openModal = () => {
      setIsEditModalOpen(true)
    }

 
    const onSelected = (name:string) => {
        console.log("selected", name);
    }

    return (
        <div className="w-full flex-col">
            <div className="flex w-full items-center justify-between">
               <div className="text-light">Soft Skills</div>
                <button onClick={()=>openModal()} className=""><Icon name="pencil" /></button>
            </div>
            <div className="flex flex-wrap items-center  gap-2 pt-2 pb-4">
                {
                  softSkills.length>0 &&  value?.map((softSkillId) => {
                        return <SoftSkillSpan key={softSkillId} name={softSkills?.filter(i=>i.id===softSkillId)[0]?.name} onClick={() => onSelected(softSkillId)} unicode={ softSkills?.filter(i=>i.id===softSkillId)[0]?.unicode}/>
                    })
             }
            </div>
            <Modal title="Soft Skills (Top 5)" closeButtonName="Done" onClose={closeModal} show={isEditModalOpen}>
                <SoftSkillsEdit user={user} activeSoftSkills={value} allSkills={ softSkills}/>
            </Modal>
        </div>
    )
}
