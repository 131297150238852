
import { auth} from "../firebase";
import { useAuthState } from 'react-firebase-hooks/auth';


export const useProfileUrl = () => {
    const [userAuthData, loading, error] = useAuthState(auth);
    return {photoUrl:userAuthData?.photoURL||""}
}

