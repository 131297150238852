import React,{Children} from 'react'


interface PageNotFoundProps{
    className?: string;
}


const PageNotFound:React.FC<PageNotFoundProps> = ({className, children}) => {
    return (
        <div className="h-screen ">
                {Children.toArray(children)[0]}
             <div className="h-screen pt-16  px-primary" >
               <div className="text-4xl sm:text-6xl lg:text-7xl leading-none font-extrabold tracking-tight text-primary mt-10 mb-8 sm:mt-14 sm:mb-10">
               Page Not Found
                </div>
                <p className="max-w-screen-lg text-lg sm:text-2xl sm:leading-10 font-medium mb-10 sm:mb-11 text-secondary">
               Ooops.. we couldn't find what you were looking for.
                </p>
            </div>
            {Children.toArray(children)[1]}
            </div>
    )
}

export { PageNotFound }
