import React from 'react'
import {Icon } from "../../components/Icons";
import {capitalCase } from "change-case";

interface KeywordsCardProps{
    keywords?: Array<string>;
}


export const KeywordsCard: React.FC<KeywordsCardProps> = ({ keywords }) => {
    if (!keywords) {
        return null;
    }
    return (
        <div className="flex flex-col items-center justify-center text-center border rounded-md p-2 max-w-xs">
            <div className="text-primary-accent text-xl flex items-center justify-center"><Icon name="bolt"/></div>
            <div className="flex flex-row space-x-2 flex-wrap items-center justify-center">
                {keywords.map((keyword) => {
                    return <div className="">{ capitalCase(keyword) }</div>
                })}

            </div>
        </div>
    )
}
