import React, {  useState, useEffect } from 'react'
import {Input } from "../Common";
import { Button } from "../Common";
import { OAuthLogin } from './OAuthLogin';
import { Or } from "../Common/Or";
import { auth, db } from "../../firebase";


interface LoginProps extends BaseInterface{
    onLogin: (user:User) => void;
    onRegister: (user: User) => void;
    state: string|null;
}


interface LoginState{
    email: string;
    password: string;
}

interface RegisterState{
    firstName: string;
    lastName: string;
    email: string;
    password: string;
}

const passwordValidator = (value:string):string => {
    if (value.length < 10) {
        return "password must be at least 10 char long";
    }
    if (value.toUpperCase() === value) {
        return("password must contain at least one lowercase");
    }
    if (value.toLowerCase() === value) {
        return("password must contain at least one upper case");
    }
    if (!/[ `!@#$%^&*()_+\-=\]{};':"\\|,.<>?~]/.test(value)) {
        return("password must contain at least one special character");
    };
    return "";
}

const isValidEmail = (value: string): boolean => {
    const atLocation = value.lastIndexOf("@");
    const dotLocation = value.lastIndexOf("."); 
          
     const hasError= !(
                    atLocation > 0 &&
                    dotLocation > atLocation + 1 &&
                    dotLocation < value.length - 1 )
    if (hasError) {
                 return false
    }
    return true;
}

const isEmptyString = (value: string): boolean => {
    if (value.trim() === "") {
        return true;
    }
    return false;
}

const Error:React.FC = ({children}) => {
    return <div className="text-xs bg-red-50 rounded-lg py-3 text-red-500 text-center flex space-x-2 items-center justify-center px-2">
        <div>{children}</div>
    </div>
}

const Login: React.FC<LoginProps> = ({ children, className, onLogin, onRegister, state }) => {
    const [loginState, setLoginState] = useState<LoginState>({  email: "", password: "" });
    const [registerState, setRegisterState] = useState<RegisterState>({ firstName: "", lastName: "", email: "", password: "" });
    const [isSignUpWithEmailOn, setIsSignWithEmailOn] = useState<boolean>(state==="login");


    const [loginFieldErrors, setLoginFieldErrors] = useState({ email: "", password: "" });
    const [registerFieldErrors, setRegisterFieldErrors] = useState({ firstName: "", lastName: "", email: "", password: "" });
    const [generalError, setGeneralError] = useState("");
    const [serverError, setServerError] = useState<string|null>(null);


    useEffect(() => {
        if (loginState.password.trim() !== "") {
            setLoginFieldErrors(current => {
                return { ...current, password: passwordValidator(loginState.password)}
            })
        }
        if (registerState.password.trim() !== "") {
            setRegisterFieldErrors(current => {
                return {...current, password:passwordValidator(registerState.password)}
            })
        }
    
    }, [loginState.password, registerState.password])


    useEffect(() => {
        if (loginState.email !== "") {
            setLoginFieldErrors(current => {
             return {...current,email:`${isValidEmail(loginState.email)?"":"Invalid email address"}`}
         })
        }
        if (registerState.email !== "") {
            setRegisterFieldErrors(current => {
                return {...current,email:`${isValidEmail(registerState.email)?"":"Invalid email address"}`}
            }) 
        }
        
    },[loginState.email, registerState.email])
    

    const toggleLoginRegister = () => {
        setServerError("");
        setGeneralError("");
        setIsSignWithEmailOn(current => !current);
    }


    const loginDivClick:React.MouseEventHandler<HTMLDivElement> = (e) => {
        e.stopPropagation();
    }
    

    const onSubmit = () => {
        if (isEmptyString(loginState.email)||isEmptyString(loginState.password)) {
            setGeneralError("At least one field is left blank.")
            return;
        }
      
        if (isEmptyString(loginFieldErrors.email) && isEmptyString(loginFieldErrors.password)) {
            auth.signInWithEmailAndPassword(loginState.email, loginState.password)
            .then((userCredential) => {
                onLogin({email:userCredential.user?.email||"",uid:userCredential.user?.uid||"",displayName:userCredential.user?.displayName||""})
            })
            .catch((err) => {
                setServerError(err.message)
        })
        }
    }


    const onSubmitRegister = () => {
        const displayName = registerState.firstName + " " + registerState.lastName;
        if (isEmptyString(registerState.email)||isEmptyString(registerState.password)||isEmptyString(registerState.firstName)||isEmptyString(registerState.lastName)) {
            setGeneralError("At least one field is left blank.")
            return;
        }
        if (isEmptyString(registerFieldErrors.email) && isEmptyString(registerFieldErrors.password)) {
            auth.createUserWithEmailAndPassword(registerState.email, registerState.password)
            .then((userCredential) => {
                if (userCredential.user) {
                    const {email,uid} = userCredential.user;
                    onRegister({ email: email || "", uid: uid, displayName: displayName })
                }
               return userCredential.user?.updateProfile({
                    displayName: displayName
              })
            })
            .catch((err) => {
               setServerError(err.message)
            });
        }
   }

    const onChange = (e: React.FormEvent<HTMLInputElement>): void => {
        setLoginState({...loginState, [e.currentTarget.name]:e.currentTarget.value})
    }


    const onChangeRegister = (e: React.FormEvent<HTMLInputElement>): void => {
        setRegisterState({...registerState, [e.currentTarget.name]:e.currentTarget.value})
    }

    const OAuthClick = () => {
        console.log("Logging in with oauth")
    }

    return (
        <div onClick={loginDivClick}  className="bg-primary  max-h-full overflow-y-scroll  flex flex-col relative mx-auto md:max-w-xl items-center rounded-lg pointer-events-auto z-50">
            {children}
            <div className="text-xl font-semibold text-primary-accent tracking-tight py-4 border-b w-full text-center  ">Log in or sign up</div>
            <div className="flex flex-col space-y-4 bg-primary my-4 w-full px-4">
                {isSignUpWithEmailOn && (
                    <>
                <Input onChange={onChange} error={loginFieldErrors.email} type="email" name="email" value={loginState.email} />
                        <Input onChange={onChange} error={loginFieldErrors.password} type="password" name="password" value={loginState.password} />
                        {serverError && <Error>{ serverError}</Error>}
                <Button onClick={onSubmit} >Continue</Button>
                    </>
                )}
                {
                    !isSignUpWithEmailOn && (
                        <>
                     <Input onChange={onChangeRegister} error={registerFieldErrors.firstName} type="text" name="firstName" value={registerState.firstName} />
                     <Input onChange={onChangeRegister} error={registerFieldErrors.lastName} type="text" name="lastName" value={registerState.lastName} />
                     <Input onChange={onChangeRegister} error={registerFieldErrors.email} type="email" name="email" value={registerState.email} />
                     <Input onChange={onChangeRegister} error={registerFieldErrors.password} type="password" name="password" value={registerState.password} />
                            {serverError && <Error>{ serverError}</Error>}
                     <Button onClick={onSubmitRegister} >Join</Button>
                     </>
                   )
                }
                <Or/>
                <OAuthLogin>
                <Button type="secondary" onClick={toggleLoginRegister}>{isSignUpWithEmailOn?"Join with Email":"Login with Email"}</Button>
                 <Button type="secondary" onClick={OAuthClick}>Continue with Google</Button>
                 <Button type="secondary" onClick={OAuthClick}>Continue with Facebook</Button>
                 <Button type="secondary" onClick={OAuthClick}>Continue with LinkedIn</Button>
                </OAuthLogin>
            </div>
         
            </div>
         
       
   
    )
}

export { Login }
