import React from 'react'
import "./Link.css";

interface LinkProps{
    className?: string;
    children?: React.ReactNode;
    type?: "regular" | "button"|"native";
    onClick?: () => void;
}


export const Link: React.FC<LinkProps> = ({ children, className, type = "regular", onClick }) => {
    if (type === "button") {
        return <div onClick={()=>onClick && onClick()} className={`link-hover-dark opacity-80  cursor-pointer border-2 rounded-xl  flex ${className}`}>
            <div className="transition-transform duration-500 transform hover:scale-90  px-4">
                {children}
                </div>
             </div>
    }
    if (type === "native") {
        return <div onClick={()=>onClick && onClick()} className={`${className} underline cursor-pointer`}>
                 {children}
         </div>
    }
    return (
        <div onClick={()=>onClick && onClick()} className={`link-hover opacity-80 cursor-pointer ${className} underline-class `}>{children}</div>
    )
}