import React, {useState, useEffect} from 'react'
import Switch from "react-switch";
import { EditableArrayString} from "../EditableText";
import { EditableWorkExperience } from './EditableWorkExperience';
import { Icon} from "../Icons";
import {storage } from "../../firebase";
import { EditableEducation } from './EditableEducation';
import { auth} from "../../firebase";
import { useProfileUrl } from '../../api/useProfileUrl';
import {updateTextField, toggleBooleans } from "../../api/JobSeekerActions";
import { updateArray } from '../../api/JobSeekerActions/updateArray';
import { SoftSkills } from "./SoftSkills";
import {ImpactStatement } from "./ImpactStatement";
import { EditableStringField } from '../Common/EditableText';
import { SectionLabelWithEdit } from './SectionLabelWithEdit';
import { Languages } from "./Languages";
import { Skills } from './Skills';
import { db} from "../../firebase";

interface UserInfoProps{
    user: User | null;
    profileData: UserProfile;
}



export const UserInfo: React.FC<UserInfoProps> = ({ user, profileData }) => {
   const {photoUrl}= useProfileUrl();
    const [currentFocussed, setCurrentFocussed] = useState<string|null>(null);

    const onRadioToggle = (name:string, value:boolean) => {
    toggleBooleans({user_id:user?.uid||"", payload:{name, value}})
   }
    
    const onSaveEditableText = (name: string, value: string) => {
       updateTextField({user_id:user?.uid||"",payload:{name,value}})
    }

    const onSaveArray = (name: string, value: Array<any>) => {
        updateArray({user_id:user?.uid||"",payload:{name,value}})
    }

    useEffect(() => {
        if (profileData?.skills.length > 0) {
            return;
        }
        if (!profileData?.preferred_job_title) {
            return;
        }
        (async () => {
           const job= await db.collection("jobs").doc(profileData?.preferred_job_title).get();
            const newJobseekerSkillsArray = job.data()?.skills?.map((skill:any) => {
              return {skill_id:skill,skill_score:6}
            })
            updateArray({user_id:user?.uid||"",payload:{name:"skills",value:newJobseekerSkillsArray??[]}})
        })();
      
    }, [profileData, profileData.preferred_job_title, profileData?.skills, user?.uid])

  
    const onChange = async(e:any) => {
        const file = e.target.files[0];
     
        try {
            const uploadTask = await storage.ref(`/jobseekers/profilePics/${user?.uid}/`).put(file);
            const downloadUrl = await uploadTask.ref.getDownloadURL();
            const currUser = await auth.currentUser;
            await currUser?.updateProfile({photoURL:downloadUrl})
        }
        catch (err) {
            alert(err);
        }
    }

    
    if (user === null) {
        return null;
    }
 
  
    return (
        <>
            <div className="p-4 flex flex-col space-y-4 w-full py-4 border">
                <div className="flex gap-2">
                   <div className=" h-16 w-16 bg-gray-100 flex border relative">
                    <div className="absolute top-0 bottom-0  right-0 left-0  z-0 border flex items-center">
                        <img alt="" className="object-scale-down" src={ photoUrl} />
                    </div>
                    <div className="absolute bottom-0.5 right-0.5 z-10 text-primary-accent text-md bg-purple-200 rounded-full"><Icon name="add_fill" /></div>
                    <label className="w-full z-10 flex flex-col items-center cursor-pointer  opacity-0">
                        <input type="file" className="hidden"  onChange={ onChange}/>
                    </label>
                   </div>
                   <div className="flex flex-col ">
                     <div className="text-md font-semibold flex space-x-1 items-center">
                        <div>{user.displayName}</div>
                        <div className="text-xs "><Icon name="verified"/></div>
                    </div>
                       <div className="text-sm">{ profileData.headline}</div>
                       <div className="text-xs text-gray-500">{ "New York"}</div>
                    </div>
                 </div>
           
                <div className="flex flex-col space-y-4">
                <div className="flex justify-between border-b ">
                   <div className="">Open to work</div>
                   <Switch onChange={()=>onRadioToggle("open_to_work",!profileData.open_to_work)} checked={profileData.open_to_work}></Switch>
                </div>
                    <div className="flex flex-col border-b">
                        <SectionLabelWithEdit name="headline" editIcon onEditClick={()=>setCurrentFocussed("headline")}/>
                        <EditableStringField name="headline" focus={currentFocussed === "headline"} onChange={onSaveEditableText} value={profileData.headline }/>
                </div>
                    <div className="flex justify-between border-b">
                    <EditableArrayString name="languages" onSave={onSaveArray} value={ profileData.languages}/>
                     </div>
                <div className="flex flex-col border-b">
                        <SectionLabelWithEdit name="Professional Summary" editIcon onEditClick={() => setCurrentFocussed("professional_summary")} />
                        <EditableStringField name="professional_summary" focus={currentFocussed === "professional_summary"} onChange={onSaveEditableText} value={profileData.professional_summary} />
                </div>
                <div className="flex justify-between border-b">
                    <EditableWorkExperience name="work_experiences" onSave={onSaveArray} value={profileData.work_experiences}/>
                </div>
                <div className="flex justify-between border-b">
                    <EditableEducation name="education" onSave={onSaveArray} value={ profileData.education}/>
                    </div>
                </div>
            </div>
            <div className=" p-4  border ">
                <div className="flex justify-between border-b">
                    <SoftSkills user={ user} value={profileData?.soft_skills }/>
                </div>
                <div className="flex justify-between border-b">
                    <ImpactStatement user={user} value={profileData?.impact_statement }/>
                </div>
                <div className="flex justify-between border-b">
                    <Skills user={user} value={profileData?.skills }/>
                </div>
             </div>
</>
        
    )
}


