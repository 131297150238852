import React from 'react'
import { useDisableBodyScroll } from "../Hooks/useDisableBodyScroll";
import { Icon} from "../Icons";

interface ModalProps{
    show: boolean;
    className?: string;
    onModalClose: () => void;
}



interface ModalCloseButtonProps extends BaseInterface{
    onClose: () => void;
}

const ModalCloseButton:React.FC<ModalCloseButtonProps> = ({className, onClose}) => {
    return <div onClick={onClose} className="absolute bg-primary   rounded-full border-gray-300 bg-opacity-25 right-2 top-4 pointer-events-auto z-50">
    <Icon name="close" />
    </div>
}

const Modal: React.FC<ModalProps> = ({ className, children, show, onModalClose }) => {
    
    useDisableBodyScroll(show);
      
    if (!show) {
        return null;
    }
    
    return (
        <div onClick={onModalClose} className={`${className} fixed  h-screen w-full pt-8 sm:pt-10 md:p-12  bg-gray-800 bg-opacity-60  z-10`}>
            {children}
        </div>
    )
}

export { Modal, ModalCloseButton }
