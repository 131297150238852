import  {useEffect, useState} from 'react'
import { db } from '../../firebase';

export const useJobListing = ({ business_profile_id }: { business_profile_id: string }) => {
    const [jobListings, setJobListings] = useState<Array<any>>([]);
    useEffect(() => {
        (() => {
           const unsubscribe= db.collection("job_postings").where("created_by", "==", business_profile_id).orderBy('created_at', "desc").onSnapshot((querySnapshot) => {
            setJobListings(() => []);
              querySnapshot.forEach((doc) => {
                    console.log(doc.data())
                    setJobListings(curr => [...curr, { ...doc.data(), job_listing_id:doc.id }])
             })
           });
           return () => {
            unsubscribe();
        }
        })();
    }, [business_profile_id])
    
    return [jobListings];
}
