import React from "react";
import { CSSTransition } from "react-transition-group";
import "./Overlay.css";

interface OverlayProps extends BaseInterface{
  show: boolean;
  onClick: () => void;
}


export const Overlay:React.FC<OverlayProps> = ({ show, onClick }) => {
  return (
    <CSSTransition in={show} timeout={400} classNames="overlay" unmountOnExit>
      <div
        className="absolute top-0 left-0 h-screen w-screen z-40 bg-overlay"
        onClick={onClick}
      ></div>
    </CSSTransition>
  );
};

