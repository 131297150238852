import React from 'react'


interface ButtonProps{
    className?: string;
    type?: "primary" | "secondary"|"small";
  children?: React.ReactNode;
  onClick: () => void;
}

export const Button: React.FC<ButtonProps> = ({ className, type, children, onClick }) => {
    if (type === "secondary") {
        return <button onClick={onClick} className=" transition duration-200 hover:border hover:border-indigo-700 h-12 rounded-lg border-2 border-indigo-200">
      {children}
        </button>
    }
  if (type === "small") {
    return <button onClick={ onClick} className=" h-8 px-2  text-white rounded-lg transition duration-200 bg-gradient-to-r from-purple-500 text-primary-light to-purple-600 hover:from-purple-600 hover:to-purple-500 font-extralight text-sm">{children }</button>
  }
    return (
        <button onClick={onClick} className="transition duration-200 bg-gradient-to-r from-purple-500 text-primary-light to-purple-600 hover:from-purple-600 hover:to-purple-500 h-12 rounded-lg">
          {children}
        </button>
    )
}
