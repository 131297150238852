import React, { useState, useEffect} from 'react'
import { GoogleMap, LoadScript,Marker } from '@react-google-maps/api';
import { SubmitSection} from "../Common";
import {db, firebase } from "../../firebase";
import { Error} from "../Common";

interface LocationProps extends BaseInterface{
    user: User | null;
    onSaveClick: () => void;
    isBusiness: boolean;
}


type LocationState = "waiting" | "permission_received"|"error"|"sending";

const Location: React.FC<LocationProps> = ({ className, children,  onSaveClick, user, isBusiness}) => {
    const [locationState, setLocationState] = useState<LocationState>("waiting");
    

    const [defaultCenter, setDefaultCenter]=useState({
        lat: null, lng: null
    })
    

    const successHandler = (position: any) => {
        setLocationState("permission_received");
        setDefaultCenter(() => { return { lat: position.coords.latitude, lng: position.coords.longitude } })
    };

    const errorHandler = (error: any) => {
        setLocationState("error")
    } 


    const onLocationDoneBusiness = (defaultCenter:any) => {
        db.collection("businesses").doc(user?.uid).update({
            location: new firebase.firestore.GeoPoint(defaultCenter.lat, defaultCenter.lng),
        }).then(() => {
            onSaveClick();
        })
        .catch((err) => {
          console.log(err)
         })
    }

    const onLocationDoneUser = (defaultCenter:any) => {
        db.collection("jobseekers").doc(user?.uid).update({
            location: new firebase.firestore.GeoPoint(defaultCenter.lat, defaultCenter.lng),
        }).then(() => {
            onSaveClick();
        })
            .catch(() => {
          console.log("not written")
      })
    }


    useEffect(() => {
        navigator.geolocation.getCurrentPosition(successHandler, errorHandler);
    }, [])
    

    useEffect(() => {
        if (!defaultCenter.lat|| !defaultCenter) {
            return;
        }
        setLocationState("sending");
        if (isBusiness) {
            onLocationDoneBusiness(defaultCenter);
        }
        if (!isBusiness) {
            onLocationDoneUser(defaultCenter);
        }
    },[defaultCenter])


    const mapStyles = {
        height: "100vh",
        width: "100%"
    };
    
    
    return (
        <LoadScript
            googleMapsApiKey="AIzaSyDpy979UsYzmoVJvqI5S61UHeiT1Th0zhQ"
        >
            {locationState === "error" && <Error>{locationState}</Error>}
            {locationState === "permission_received" && <div className="text-xs bg-green-50 rounded-lg py-2 text-green-500 text-center flex items-center justify-center">{"Permission Received.."}</div>}
            {locationState === "sending" && <div className="text-xs bg-purple-50 rounded-lg py-2 text-purple-500 text-center flex items-center justify-center">{"Sending your location to match you up with limitless job opportunities..."}</div>}
            {locationState === "waiting" && <div className="text-xs bg-yellow-50 rounded-lg py-2 text-yellow-600 text-center flex items-center justify-center">{"Loading the map.." }</div>}
         <GoogleMap
        mapContainerStyle={mapStyles}
           zoom={13}
           center={defaultCenter}
            />

            {defaultCenter.lat&&<Marker position={defaultCenter} />}
            <SubmitSection onSaveClick={onSaveClick }/>
         </LoadScript>
    )
}

export { Location }
