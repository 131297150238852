import React from 'react'
import { JobSeekerProfileNavItem } from './JobSeekerProfileNavItem';
import {UserInfo} from "./UserInfo";
import { UserPreference } from './UserPreference';

interface JobSeekerProfileProps extends BaseInterface{
    state: string;
    onJobPreferenceClick: ()=>void;
    onViewProfileClick: () => void;
    user: User | null;
    profileData:UserProfile;
}

const UserProfileDefault: UserProfile = {
    availability: {available_days:[],available_shifts:[]},
    education: [],
    headline: "",
    languages: [],
    location: "",
    open_to_work: true,
    preferred_hourly_rate: 0,
    professional_summary: "",
    soft_skills: [],
    preferred_job_title: "",
    work_experiences: [],
    impact_statement: {
        previous_job: "",
        achieved_verb: "",
        achieved_what: "",
        did_verb: "",
        did_what:""
    },
    skills:[]
}


export const JobSeekerProfile: React.FC<JobSeekerProfileProps> = ({ state, onJobPreferenceClick, onViewProfileClick, user, profileData }) => {
    
   
    
    if (state !== "profile"&& state!=="preference") {
        return null;
    }


    return (
       <div className="flex flex-col z-0">
            <div className="shadow-lg flex space-x-4 pt-2 px-4 sticky top-16  z-30 backdrop-filter backdrop-blur firefox:bg-opacity-90">
                <JobSeekerProfileNavItem active={state==="profile"} name="Build your profile" onClick={onViewProfileClick} />
                <JobSeekerProfileNavItem active={state==="preference"} name="Job Preference" onClick={onJobPreferenceClick} />
            </div>
            <div className="max-w-full mx-auto pt-4">
            {state === "profile" &&
                    <div className="grid grid-cols-1 md:grid-cols-2 md:gap-6">
                        <UserInfo user={user} profileData={{ ...UserProfileDefault, ...profileData }}/>
                    </div>
            }
            {state === "preference" &&
                <div className="grid grid-cols-1 md:grid-cols-2 md:gap-6">
                        <UserPreference user={user} profileData={{ ...UserProfileDefault, ...profileData }}/>
                 </div>
                }
             </div>
        </div >
    )
}
