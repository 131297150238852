import React from 'react'
import { Icon} from "../../components/Icons";

interface LanguagesCardProps extends BaseInterface{
    languages: Array<string>;
}

export const LanguagesCard:React.FC<LanguagesCardProps> = ({languages}) => {
    return (
        <div className="flex flex-col border rounded-md p-2">
            <div className="text-primary-accent text-xl flex items-center justify-center"><Icon name="bulb"/></div>
            {languages?.map((language, i) => {
                return <div key={i} className="">{language }</div>
            })}
        </div>
    )
}
