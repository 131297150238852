import React from 'react'
import { Icon } from '../Icons'

interface SectionLabelWithEditProps{
    name: string;
    editIcon?: boolean;
    onEditClick?: () => void;
}


export const SectionLabelWithEdit:React.FC<SectionLabelWithEditProps> = ({name,editIcon,onEditClick}) => {
    return (
        <div className="text-light w-full flex items-center justify-between">
            <div className="capitalize">{name}</div>
            {editIcon&&onEditClick&&<button className="" onClick={onEditClick} ><Icon name="pencil"/></button>}
        </div>
    )
}
