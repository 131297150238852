import React, { useEffect, useState} from 'react'
import { Icon} from "../Icons";
interface InputProps extends BaseInterface{
    type: string;
    name: string;
    value: string;
    error?: string;
    onChange: (e:React.FormEvent<HTMLInputElement>) => void;
}


const Input: React.FC<InputProps> = ({ name, type, value, onChange, className, error }) => {


    return (
        <div className={`flex flex-col  ${className}`} >
            <div className="text-sm font-semibold capitalize px-1 ">{name}</div>
            <input onChange={onChange} name={name} type={type} value={value} className={`border shadow-sm  focus:outline-none focus:ring-2  focus:ring-opacity-50 rounded-lg h-10 ${error && (error?.trim().length>0) ? "focus:ring-red-600 border-red-300" : "focus:ring-green-300"}`} />
            {error && (error?.trim().length>0) &&<div className="text-xs text-red-400 flex items-center"> <Icon name="error"/>{error}</div>}
        </div>
    )
}

export { Input }
