import React, { useState, useEffect} from 'react'
import {NavBusiness } from "../components/NavBusiness";
import { Icon } from "../components/Icons";
import {InteractionBoxBusiness } from "../components/InteractionBoxBusiness";
import {BusinessSideBar } from "../components/BusinessSideBar";
import {BusinessCandidateSelection } from "../components/BusinessCandidateSelection";
import {useSubscribedCandidates } from "../api/Business/useSubscribedCandidates";
import {SideNav,SideNavMenuCategory,SideNavMenuItem } from "../components/SideNav";
import {useHistory } from "react-router-dom";
import {  BusinessSideBarHeader} from "../components/BusinessSideBar/BusinessSideBarHeader";
import {BusinessJobPostingForm} from "../components/BusinessJobPostingForm"
import {useJobListing} from "../api/Business/useJobListing";


interface BusinessProps extends BaseInterface{
    onLogOutClick: () => void;
    user:User|null;
}
    

export const Business:React.FC<BusinessProps> = ({user,onLogOutClick}) => {
    const [width, setWidth] = React.useState(window.innerWidth);
    const [isAbsoluteChatToggleable,setIsAbsoluteChatToggleable ] = useState<boolean>(true);
    const [absoluteChatOn, setAbsoluteChatOn] = useState<boolean>(false);
    const [jobListings] = useJobListing({ business_profile_id: user?.uid??"" });
    const [activeJobListingId, setActiveJobListingId] = useState < string|null>(null);
    const {subscribedCandidates} = useSubscribedCandidates({business_profile_id:user?.uid||""});
    const [isLeftSideBarOn, setIsLeftSideBarOn] = useState<boolean>(false);
    const [isJobPostingFormOn, setIsJobPostingFormOn] = useState<boolean>(false);
    const history = useHistory();


    useEffect(() => {
        window.addEventListener("resize", () => setWidth(window.innerWidth));
    }, [])

    useEffect(() => {
        if (width > 1024) {
            setIsAbsoluteChatToggleable(false);
        }
        if (width < 1024) {
            setIsAbsoluteChatToggleable(true);
        }
    },[width])
    
   
    const onToggleChatBox = () => {
        if (!isAbsoluteChatToggleable) {
            return;
        }
        setAbsoluteChatOn(!absoluteChatOn);
    }

    const onCloseChatBox = () => {
        if (!isAbsoluteChatToggleable) {
            return;
        }
        setAbsoluteChatOn(false);
    }



    const changeActiveListing = (activeListingId: string) => {
        setActiveJobListingId(activeListingId);
        setIsJobPostingFormOn(false);
    }


    const logOut = () => {
        onLogOutClick();
        history.push("/");
    }

    const onJobPreferenceClick = () => {
        
    }

    const onViewProfileClick = () => {
        
    }

    const onSideBarToggle = () => {
        setIsLeftSideBarOn(current => !current);
    }

    const onPostAJobClick = () => {
        setIsJobPostingFormOn(!isJobPostingFormOn);
    }


    return (
        <div>
               <NavBusiness onProfileClick={onSideBarToggle} user={user} showChat={true} onToggleChatBox={() => console.log("toggling")} >
                 <div onClick={onToggleChatBox} className="text-2xl">
                    <Icon name="message" />
                 </div>
            </NavBusiness>
            <SideNav onSideBarToggle={onSideBarToggle} onLogOutClick={logOut} isJobSeekerSideNav={false} onJobPreferenceClick={onJobPreferenceClick} onViewProfileClick={onViewProfileClick} user={user} onClose={onSideBarToggle} show={isLeftSideBarOn}>
                        <SideNavMenuItem onClick={()=>console.log("home") } name="Home" subTitle="Business" iconName="home" />
                        <SideNavMenuItem onClick={()=>console.log("home")  } name="My Job Posts" subTitle="Last job post published on Mar 28, 2021"  iconName="briefcase" />
                        <SideNavMenuItem onClick={()=>console.log("home")  } name="Chat" subTitle="Last activity on Mar 28, 2021"  iconName="message" />
                         <SideNavMenuCategory name="Settings"/>
                        <SideNavMenuItem onClick={()=>console.log("home")  } name="Account Login" subTitle="Password and security" iconName="lock" />
                        <SideNavMenuItem onClick={()=>console.log("home")  } name="Location Settings" subTitle="New York, NY, USA"  iconName="profile_setting" />
                        <SideNavMenuItem onClick={()=>console.log("home")  } name="User verification" subTitle="+1 212 4361133" iconName="verified" />
            </SideNav>
                <div className="h-screen" >
                <div className="flex h-full w-full pt-16 max-w-8xl m-auto">
                   
                    {isAbsoluteChatToggleable && <div className="absolute left-0 right-0 z-50 sm:left-1/2 sm:right-0">
                        <InteractionBoxBusiness subscribedCandidates={subscribedCandidates} show={absoluteChatOn} user={user} onClose={onCloseChatBox} isAbsolute={true} />
                    </div>}
                    
                    <div className="hidden sm:block sm:max-w-xxs sm:overflow-y-auto sm:flex-none border">
                        <BusinessSideBar jobListings={jobListings} user={user} isJobPostActive={isJobPostingFormOn} activeJobListingId={activeJobListingId} changeActiveJobListing={changeActiveListing} >
                            <BusinessSideBarHeader onClick={onPostAJobClick} active={isJobPostingFormOn }/>
                        </BusinessSideBar>
                    </div>

                   <div className="flex-1 overflow-y-scroll ">
                        {!isJobPostingFormOn && <BusinessCandidateSelection activeJobListingId={activeJobListingId} activeJobId={(jobListings.length>0 && jobListings?.filter(jobListing=>jobListing.job_listing_id===activeJobListingId)[0]?.job_id)??""} />}
                        {isJobPostingFormOn && <BusinessJobPostingForm user={ user}/>}
                    </div>
                    <div className="hidden lg:block lg:max-w-sm lg:overflow-y-scroll  border">
                        <InteractionBoxBusiness show={!isAbsoluteChatToggleable} user={user} onClose={onCloseChatBox} subscribedCandidates={subscribedCandidates} isAbsolute={false} />
                    </div>
                </div>
                </div>
        </div>
    )
}
