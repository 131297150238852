import React, {useEffect, Children} from 'react'
import { ReactComponent as CandidateCard1 } from "../static/svgs/candidateCard1.svg";
import { ReactComponent as CandidateCard2 } from "../static/svgs/candidateCard2.svg";
import { ReactComponent as CandidateCard3 } from "../static/svgs/candidateCard3.svg";
import { ReactComponent as JobPostingSideBar} from "../static/svgs/jobPostingSideBar.svg";
import { Link } from "../components/Common";
import {useHistory} from "react-router-dom";
import { ButtonSubmitPrimary } from '../components/Common/ButtonSubmitPrimary';
import { Icon} from "../components/Icons";



interface HomeProps{
    className?: string;
    onHireTalentClick: () => void;
    onApplyJobClick: () => void;
    isJobSeeker: boolean|null;
    user: User | null;
    children?: React.ReactNode;
}


const Home: React.FC<HomeProps> = ({ className, onApplyJobClick, onHireTalentClick, user, isJobSeeker, children }) => {
    const history=useHistory();

    useEffect(() => {
        if (!user?.email) {
            return;
        }
        if (isJobSeeker === null) {
            return;
        }
        if (isJobSeeker) {
            history.push("/apply"); 
        }
        history.push("/hire");
    }, [user, isJobSeeker, history])

    return (
     <>
            <div className={`bg-primary text-primary min-h-screen  ${className}`}>
                {Children.toArray(children)[0]}
                {Children.toArray(children)[1]}
                <div className=" md:my-24 md:flex   md:items-start ">
                   <div className="pt-20 md:pt-0 md:flex-1 px-primary flex flex-col space-y-8 ">
                       <div className="text-4xl sm:text-5xl lg:text-6xl leading-none font-extrabold tracking-tight">The Hiring Exchange </div>
                      <div className="text-secondary-dark">A premier hiring platform for small to medium-sized business owners and local talent pool. We provide a cutting-edge interface that correctly identifies appropriate candidates and allows talent to simplify an effective job search.</div>
                        <div className="flex ">
                            <ButtonSubmitPrimary className="px-10"  active={true}  onClick={onHireTalentClick}>Hire A JobSeeker</ButtonSubmitPrimary>
                       </div>
                       <div className="flex flex-row space-x-4">
                            <span className="text-secondary-dark">Looking for job?</span>
                            <Link type="native" className="text-primary-accent flex items-center gap-2" onClick={onApplyJobClick}>
                                <div>Apply for Job</div>
                                <div><Icon name="arrow_right_primary"/></div>
                            </Link>
                            
                       </div>
                    </div>
                    <div className={`flex flex-col py-20 md:pt-0 md:items-center lg:items-end md:flex-1 px-primary overflow-x-hidden overflow-y-visible`}>
                        <div className="flex justify-start">
                            <CandidateCard1 className="" />
                             <CandidateCard2 className="" />
                        </div>
                        <div className="flex justify-start">
                            <CandidateCard3 className="" />
                           <JobPostingSideBar className="" />
                        </div>
                 </div>
            
                </div>
      </div>
            {Children.toArray(children)[2]}
            {Children.toArray(children)[3]}

        </>
    )
}

export { Home }
