import React, {useState, useEffect} from 'react'
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import { db } from "../../firebase";
import {SubmitSection } from "../Common";
import { countries} from "../../constants/countries";
import {Error } from "../Common";


interface BusinessInfoProps extends BaseInterface{
  user: User | null;
    onComplete: () => void;
}


interface BusinessData{
    created_by: string|null;
    company_name: string|null;
    street_address: string|null;
    city: string|null;
    state: string|null;
    postal_code: number|null;
    country: string|null;
    phone: string|null;
}

interface BusinessDataError{
  created_by: string|null;
  company_name: string|null;
  street_address: string|null;
  city: string|null;
  state: string|null;
  postal_code: string|null;
  country: string|null;
  phone: string|null;
}

export const BusinessInfo: React.FC<BusinessInfoProps> = ({ onComplete, user }) => {
  
  const [businessFormData, setBusinessFormData] = useState<any>({
    city: null,
    company_name: null,
    country: "US",
    created_by: null,
    phone:null,
    state: null,
    postal_code: null,
    street_address:null
  });
  
  const [error, setError] = useState<BusinessDataError>({
    city: null,
    company_name: null,
    country: null,
    created_by: null,
    phone: null,
    state: null,
    postal_code: null,
    street_address:null
  });

   
  const [generalError, setGeneralError] = useState<null|string>("");
  
  const onSave = async () => {
     
    const { city, company_name, country, created_by, phone, postal_code, state, street_address } = businessFormData;
    console.log(businessFormData);
    
    if (city === null || company_name === null || country === null || created_by === null || phone === null || postal_code === null || state === null || street_address === null) {
      setGeneralError("Some fields are left blank");
      return;
    }
    
   
    
    if (error.city !== null || error.company_name !== null || error.country !== null || error.created_by !== null || error.phone !== null || error.postal_code !== null || error.state !== null || error.street_address !== null) {
      setGeneralError("Some fields are empty or have error.");
      return;
    }

    try {
       await db.collection("business_profiles").doc(user?.uid).set(businessFormData);
      onComplete();
      }
    catch (err) {
      setGeneralError("Cannot send form data.")
      }
  }
  
  useEffect(() => {
    Object.keys(businessFormData).forEach((key: any) => {
      if (businessFormData[key] === null) {
        return;
      }
      if (businessFormData[key].trim() === "") {
        setError(current => {
          return {
            ...current,
            [key]:"Cannot be blank"
          }
        })
      }
      else {
        setError(current => {
          return {
            ...current,
            [key]:null
          }
        })
      }
    })
   
  }, [businessFormData])
  
  const onChangeHandler = (e:React.ChangeEvent<HTMLInputElement>) => {
    setBusinessFormData((current:any) => {
      return {
        ...current,
        [e.target.id]: e.target.value,
     }
    })
  }

  const onChangeHandlerSelect = (e: React.ChangeEvent<{ name?: string; value: unknown; }>) => {
    setBusinessFormData((current:any) => {
      return {
        ...current,
        country: e.target.value,
     }
    })
  }


  return (
    <>
      {generalError && <Error>{ generalError}</Error>}
        <div className="flex flex-col h-full w-full mb-24 mt-4  space-y-4">
            <TextField
          required
          onChange={onChangeHandler}
          
          id="created_by"
          label="Your name"
          error={error.created_by!==null}
          variant="outlined"
          value={businessFormData.created_by}
            size="small"
            />
              <TextField
          required
          onChange={onChangeHandler}
          error={error.company_name!==null}
          id="company_name"
          label="Your company name"
          variant="outlined"
          size="small"
          value={businessFormData.company_name}

            />
              <TextField
          required
          onChange={onChangeHandler}
          error={error.street_address!==null}
          id="street_address"
          label="Street address"
          variant="outlined"
          size="small"
          value={businessFormData.street_address}

            />
              <TextField
          required
          onChange={onChangeHandler}
          error={error.city!==null}
          id="city"
          label="City"
          variant="outlined"
          size="small"
          value={businessFormData.city}

            />
              <TextField
          required
          onChange={onChangeHandler}
          error={error.state!==null}
          id="state"
          label="State"
          variant="outlined"
          size="small"
          value={businessFormData.state}

            />
              <TextField
          required
          onChange={onChangeHandler}
          error={error.postal_code!==null}
          id="postal_code"
          label="Postal Code"
          variant="outlined"
          size="small"
          value={businessFormData.postal_code}
            />
         
          <Select
          native
          required
          id="country"
          error={error.country!==null}
          style={{height:"2.6rem"}}
          variant="outlined"
          onChange={onChangeHandlerSelect}
          label="Country"
          defaultValue={"US"}
        >
          <option aria-label="Country" value="Country" />
          {countries.map((item) => {
            return <option value={item.code}>{item.name }</option>
           })}
        </Select>
        
        
           <TextField
          required
          error={error.phone!==null}
          onChange={onChangeHandler}
          id="phone"
          label="Phone"
          variant="outlined"
          size="small"
          value={businessFormData.phone}
            />
         <SubmitSection onSaveClick={onSave } />
      </div>
      </>
    )
}
