import { Icon, IconNames } from "../Icons";



interface TabProps extends BaseInterface {
    frequency: number;
    iconName: IconNames;
    active: boolean;
    name: string;
    onClick: (tabName: string) => void;
}


export const Tab: React.FC<TabProps> = ({ active, iconName, frequency, name, onClick }) => {

    return <div onClick={() => onClick(name)} className={`${active && "border-b-4 border-accent"} h-full w-12 flex items-center space-x-1 cursor-pointer text-purple-700`}>
        <div className="text-md"> <Icon name={iconName} /></div>
        <div className="text-xs">{frequency > 99 ? `99+` : frequency}</div>
    </div>
}