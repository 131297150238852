import  { useEffect, useState } from 'react'
import { db,businessesRef } from "../firebase";

export const useListing = ({user_id}:{user_id:string}) => {
    const [jobListings, setJobListings] = useState<Array<any>>([])

    useEffect(() => {
        if (user_id === "") {
            return;
        }

        (async () => {
            const querySnapshot = await db.collection("job_postings").get();
            setJobListings((_)=>[]);
             querySnapshot.forEach(async (doc) => {
                const isSubscribedAlready = await db.collection("subscribed_jobs").doc(`${doc.id}_${user_id}`).get();
               
                 if (!isSubscribedAlready.exists) {
                    const businessInfo= await businessesRef.doc(doc.data().created_by).get();
                     setJobListings((current) => [...current, { ...doc.data(),...businessInfo.data(), id: doc.id }]);
                }
            })
        })();

    }, [user_id])

    return [jobListings];

}

