import React, {useState, useEffect} from 'react'
import {Icon,IconNames } from "../Icons";
import {Button } from "../Common";
import Switch from "react-switch";
import {SubmitSection } from "../Common";
import { Error} from "../Common";
import { db } from '../../firebase';

interface AvailabilityProps extends BaseInterface{
    onSaveClick: () => void;
    user: User | null;
}


type dictNum = {
    [key: number]: string;
}

type dict<T> = {
    [key: string]: T;
}

const Day:dictNum = {
    1: "Mon",
    2: "Tue",
    3: "Wed",
    4: "Thu",
    5: "Fri",
    6: "Sat",
    7:"Sun"
}

const HoursForShift:dict<Array<number>> = {
    "Morning": [5, 6, 7, 8, 9, 10, 11],
    "Afternoon": [12, 13, 14, 15, 16],
    "Evening": [17, 18, 19, 20],
    "Night":[21,22,23,24,1,2,3,4],
}



interface ShiftInDayCardProps extends BaseInterface{
    iconName: IconNames;
    time: string;
    shift: string;
    onClick: (shiftName: string) => void;
    active: boolean;
}

interface DayCardProps extends BaseInterface{
    dayNum: number;
    active: boolean;
    onClick : (dayNum: number) => void;
}

export const ShiftInDayCard: React.FC<ShiftInDayCardProps> = ({iconName,shift,time, onClick, active}) => {
    return <div onClick={() => onClick(shift)} className={`${active && "bg-primary-dark text-primary-light hover:opacity-90"}  h-20 w-24 py-1 flex flex-col items-center justify-center  border rounded-lg shadow-md cursor-pointer transition duration-100 ease-in hover:opacity `}>
        <div><Icon name={iconName}/></div>
        <div>{shift}</div>
        <div className="opacity-50 text-sm">{time }</div>
    </div>
}

export const OtherTimeSlotCard: React.FC = ({children}) => {
    return <Button type="secondary" onClick={()=>console.log("Adding more time slots")} className="h-10 border">Add more time slots</Button>
}

export const DayCard: React.FC<DayCardProps> = ({ children,onClick , dayNum, active}) => {
    return <div key={dayNum} className={` ${active&&"bg-primary-dark text-primary-light hover:opacity-90"} h-8 w-12 border rounded-lg flex justify-center items-center`} onClick={() => onClick(dayNum)}>{Day[dayNum]}</div>
}

export const Availability: React.FC<AvailabilityProps> = ({ className = "", children, onSaveClick, user }) => {
    const [availableDays, setAvailableDays] = useState<Array<number>>([]);

    const [activeShift, setActiveShift] = useState<Array<string>>([]);

    const [allDayOn, setAllDayOn] = useState<boolean>(false);
   
    const [availabilityList, setAvailabiltyList] = useState<Array<string>>([]);

    const [error, setError] = useState<null|string>(null);

    useEffect(() => {
        const avList:Array<string> = [];
        availableDays.forEach((day) => {
            activeShift.forEach((shift) => {
                HoursForShift[shift].forEach((hour) => {
                    avList.push(`${day}-${hour}`)
              })
           })
        })
        setAvailabiltyList(current => {
            return avList;
        })
     },[availableDays,activeShift])



    useEffect(() => {
        if (!allDayOn) {
            setAvailableDays([]);
        }
        else {
            setAvailableDays([1,2,3,4,5,6,7])
        }
   },[allDayOn])



    const handleShiftClick = (shiftName: string) => {
        if (activeShift.includes(shiftName)) {

            setActiveShift((current) => {
                return current.filter((item) => {
                    return item!==shiftName
                })
            })
            return;
        }
        setActiveShift((current) => [...current, shiftName])
    }

  

    const handleDayClick = (dayNum:number) => {
        if (availableDays.includes(dayNum)) {
            setAvailableDays((current) => {
                return current.filter((item) => {
                    return item!==dayNum
                })
            })
            return;
        }
        setAvailableDays((current)=>[...current,dayNum])
    }


    const onRadioToggle = () => {
        setAllDayOn(!allDayOn);
    }


    const saveToDb = async () => {
        try {
            await db.collection("jobseekers").doc(user?.uid).update({
                availability: availabilityList
            });
            onSaveClick();
        }
        catch (err){
            setError("Cannot save to database.")
        }

    }


    const onSave = async () => {
        if (availabilityList.length <= 0) {
            setError("Please select at least a day and shift");
            return;
        }
        await saveToDb();
    }

    return (
        <>
    {error && <Error>{error}</Error>}
        <div className="h-full w-full flex flex-col space-y-10 py-8">
              <div className="flex items-center justify-center space-x-2">
                <label className="text-sm font-light ">I'm available all week </label>
                <Switch onChange={onRadioToggle} checked={allDayOn}></Switch>
              </div>
            <div className="flex justify-center">
                {
                    [1, 2, 3, 4, 5, 6, 7].map((item, i) => {
                        return <DayCard active={availableDays.includes(item)} onClick={handleDayClick } dayNum={item}/>
                    })
                }
            </div>
            <div className="flex justify-center space-x-2">
            <ShiftInDayCard active={ activeShift.includes("Morning") } onClick={handleShiftClick } iconName="morning" time="5am-12pm" shift="Morning" />
            <ShiftInDayCard active={ activeShift.includes("Afternoon")} onClick={handleShiftClick} iconName="afternoon" time="12pm-5pm" shift="Afternoon"/>
            <ShiftInDayCard active={ activeShift.includes("Evening")} onClick={handleShiftClick} iconName="evening" time="5pm-9pm" shift="Evening"/>
            <ShiftInDayCard active={activeShift.includes("Night")} onClick={handleShiftClick} iconName="night" time="9pm-4am" shift="Night" />
            </div>

                <SubmitSection onSaveClick={onSave} />
            </div>
            </>
    )
}
