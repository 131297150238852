import React from 'react'
import {Icon } from "../Icons";
export const Social = () => {
    return (
        <div className="flex space-x-2">
            <Icon name="twitter" />
            <Icon name="linkedin"/>
        </div>
    )
}
