import React from 'react'

interface JobSeekerProfileNavItemProps extends BaseInterface{
    name: string;
    onClick: () => void;
    active: boolean;
}

export const JobSeekerProfileNavItem:React.FC<JobSeekerProfileNavItemProps> = ({name,onClick, active}) => {
    return (
        <div onClick={onClick} className={`relative cursor-pointer tracking-tight`}>  
            <div className="pb-2">{name}</div>
            <div className={`${active&& "left-0 top-0 h-1 bg-blue-950 rounded-lg"} `}></div>
        </div>
    )
}
