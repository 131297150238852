import { db, firebase} from "../firebase";

interface ActionArgs {
    job_listing_id: string;
    user_id: string;
}

export const applyJob = async ({ job_listing_id, user_id }: ActionArgs) => {
    console.log("happening once apply")
    try {
        await db.collection("subscribed_jobs").doc(`${job_listing_id}_${user_id}`).set({
            job_posting_id:job_listing_id,
            jobseeker_id: user_id,
            applied: true,
            passed: false,
            saved: false,
            time_applied:firebase.firestore.FieldValue.serverTimestamp(),
        }, {merge:true});
        return true;
    }
    catch (err) {
        console.log(err);
        return false;
    }

}

export const passJob = async ({ job_listing_id, user_id }: ActionArgs) => {
    try {
        await db.collection("subscribed_jobs").doc(`${job_listing_id}_${user_id}`).set({
            job_posting_id:job_listing_id,
            jobseeker_id:user_id,
            applied: false,
            passed: true,
            saved: false,
            time_passed:firebase.firestore.FieldValue.serverTimestamp(),
        }, {merge:true});
        return true;
    }
    catch (err) {
        return false;
    }

}

export const saveJob = async ({ job_listing_id, user_id }: ActionArgs) => {
    try {
        await db.collection("subscribed_jobs").doc(`${job_listing_id}_${user_id}`).update({
            job_posting_id:job_listing_id,
           jobseeker_id: user_id,
            applied: false,
            passed: false,
            saved: true,
        });
        return true;
    }
    catch (err) {
        return false;
    }
}
