
import {Button } from ".";

interface SubmitSectionProps extends BaseInterface{
    onSaveClick: () => void;
}


export const SubmitSection: React.FC<SubmitSectionProps> = ({ onSaveClick }) => {
    return  <div className="absolute left-0 right-0 bottom-0 h-10 p-2 bg-indigo-50 bg-opacity-70">
    <div className="flex items-center justify-center h-full w-full">
    <Button onClick={onSaveClick } type="small">Save and Continue</Button>
    </div>
</div>
}