import React, {useState, useRef} from 'react'
import {Icon } from "../Icons";
import { EditableArrayString} from "./EditableArrayString";
import {sentenceCase } from "change-case";

interface EditableTextProps{
    name: string;
    onSave: (name:string, value:string) => void;
    value?: string;
}

export const EditableText:React.FC<EditableTextProps> = ({name, onSave,value}) => {
   const [isEditing, setIsEditing]= useState<boolean>(false);
    const inputBoxRef = useRef<HTMLInputElement>(null);

    const toggleIsEditing = () => {
        setIsEditing(!isEditing);
    }
    
    const valueSave = (name: string) => {
        onSave(name, inputBoxRef.current?.value || "");
        setIsEditing(!isEditing);
    }
    
    return (
        <div className="flex flex-col w-full">
        <div className="flex justify-between w-full">
                <div className="capitalize text-light">{sentenceCase(name)}</div>
                <div className=" ">
                {!isEditing &&
                <div onClick={toggleIsEditing}><Icon name="pencil" /></div>
                    }
                     {isEditing &&
                <div className="flex">
                   <div className="text-green-500 " onClick={()=>valueSave(name)} ><Icon name="check_fill" /></div>
                   <div className="text-red-600 " onClick={toggleIsEditing}><Icon name="close_fill"/></div>
                  </div>
    
                  }
                </div>
            </div>
            
           
        {!isEditing &&
            <div  className="">{ value||"No Headline"}</div>                
            }
            {isEditing && 
                <input placeholder="add" ref={ inputBoxRef}  defaultValue={value}/>}
        </div>
    )
}

export{EditableArrayString}