import React, {useState} from 'react'
import {Icon} from "../Icons";
import { ChatBox } from "../Chat";
import { useProfileUrl } from '../../api/useProfileUrl';
import { useJobSubscribed } from "../../api/useJobSubscribed";




interface NavCentralTitleProps extends BaseInterface{
    name: string;
    position: string;
    location: string;
}


const NavCentralTitle: React.FC<NavCentralTitleProps> = ({name, position, location}) => {
    return <div className="flex flex-col items-center ">
        <div className="text-md">{name}</div>
        <div className="flex text-xs text-gray-400 items-center">
            <div>{position}</div>
            <div className="px-1">•</div>
            <div>{location}</div>
        </div>
    </div>
}



interface NavChatProps extends BaseInterface{
    show: boolean;
    onToggle: () => void;
    user: User | null;
    subscribedJobs: Array<any>;
}

const NavChat: React.FC<NavChatProps> = ({show, onToggle, user, subscribedJobs }) => {
    
    return <div className="">
          <div onClick={ onToggle} className="text-2xl">
                <Icon name="message" />
             </div>
            <div className=" text-gray-700 absolute top-16 left-1/2 md:left-2/3 right-0 md:right-0">
            <ChatBox onClose={onToggle} show={show} user={user} subscribedJobs={subscribedJobs} />
           </div>
         </div>
}


interface NavSearchBarProps extends BaseInterface{
  
}

const NavSearchBar: React.FC<NavSearchBarProps> = () => {
    const [isSearchBarOn, setIsSearchBarOn] = useState<boolean>(false);
    
    const onSearchClick = () => {
        setIsSearchBarOn(current => !current);
    }

    return <div onClick={onSearchClick} className="text-xl sm:flex items-center space-x-2">
        {!isSearchBarOn && <Icon name="search" />}
        {isSearchBarOn &&
            <div className="relative">
              <input className="rounded-lg h-8 w-96" name="search" />
               <div className="absolute right-1 top-1/2 transform -translate-y-1/2 text-gray-500 text-base">
                 <Icon name="search"/>
                </div>
            </div>
        }
        
    </div>
}

interface NavJobSeekerProps extends BaseInterface{
    onProfileClick: () => void;
    showChat: boolean;
    user: User | null;
    onToggleChatBox: () => void;
    location?: string;
    job_title?: string;
}


const NavJobSeeker: React.FC<NavJobSeekerProps> = ({onProfileClick, className, children, showChat, user, onToggleChatBox,job_title,location }) => {
   const {photoUrl}= useProfileUrl();
   const [subscribedJobs] = useJobSubscribed({ uid:user?.uid||"" });


    return (
        <div className="bg-blue-950 h-16 fixed left-0 right-0 text-gray-50 z-50">
            <div className="h-full w-full px-primary-snug flex items-center justify-between ">
                <div className="flex-none flex items-center space-x-4">
                        <div onClick={onProfileClick} className="rounded-full bg-gray-100  h-8 w-8 overflow-hidden flex items-center">
                            <img alt="" className="object-contain" src={photoUrl}/>
                        </div>
                   <NavSearchBar />
                 </div>
                <div className=" hidden lg:block absolute left-1/2 transform -translate-x-1/2 ">
                    <NavCentralTitle name={user?.displayName||""} location={location??"New York"} position={job_title??"Software Engineer"} />
                </div>
                <div className="flex-none flex justify-end">
                    <NavChat onToggle={onToggleChatBox} show={showChat} user={ user} subscribedJobs={ subscribedJobs}/>
                </div>
         </div>
        </div>
    )
}

export { NavJobSeeker }
