import React from 'react'

interface OAuthLoginProps extends BaseInterface{

}

const OAuthLogin:React.FC<OAuthLoginProps> = ({className, children}) => {

    return (
        <div className="flex flex-col space-y-4">
          {children}
        </div>
    )
}

export { OAuthLogin }
