import  {useEffect, useState} from 'react'
import { db} from "../../firebase";

export const useCandidateInfo = ({ job_listing_id, job_id }: { job_listing_id: string|null, job_id:string }) => {
   const [candidatesDetails, setCandidatesDetails]= useState<Array<any>>([]);

    useEffect(() => {
        (async() => {
            const allApplicants = await db.collection("subscribed_jobs").where("job_posting_id", "==", job_listing_id).get();
            setCandidatesDetails(()=>[]);
           allApplicants.forEach(async (doc) => {
                if (doc.data()?.is_denied===true || doc.data()?.is_invited===true) {
                    return;
                }
                const jobSeekerDetails = await db.collection("jobseekers").doc(doc.data().jobseeker_id).get();
                setCandidatesDetails(current=>[...current,{...doc.data(),...jobSeekerDetails.data()} ])
           })
           const matchedJobseeker = await db.collection("jobseekers").where("preferred_job_title", "==", job_id).get();
            matchedJobseeker.forEach(async(doc) => {
               const alreadySubscribed= await db.collection("subscribed_jobs").doc(`${job_listing_id}_${doc.id}`).get();
                if (alreadySubscribed.exists) {
                    return;
               }
                setCandidatesDetails(current=>[...current,{...doc.data(),jobseeker_id:doc.id}])
            })
       })()
       
    }, [job_listing_id])
    

    return { candidatesDetails };
}
