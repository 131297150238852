import  {useState, useEffect} from 'react'
import {db } from "../../firebase";

export const useGetAllSoftSkills = () => {
    const [softSkills, setSoftSkills] = useState<Array<any>>([]);

    useEffect(() => {
      
        (async () => {
            const allSkills= await db.collection("soft_skills").get();
            allSkills.forEach(doc => {
                setSoftSkills(curr => [...curr, { ...doc.data(), id:doc.id}])
            })          
        })();

        return () => setSoftSkills([]);
     },[])

    return {softSkills}
}
