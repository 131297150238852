import React from 'react'
import { useProfileUrl } from '../../api/useProfileUrl';



interface NavCentralTitleProps extends BaseInterface{
    name: string;
    position: string;
    location: string;
}



const NavCentralTitle: React.FC<NavCentralTitleProps> = ({name, position, location}) => {
    return <div className="flex flex-col items-center ">
        <div className="text-md">{name}</div>
        <div className="flex text-xs text-gray-400 items-center">
            <div>{position}</div>
            <div className="px-1">•</div>
            <div>{location}</div>
        </div>
    </div>
}




interface NavJobSeekerProps extends BaseInterface{
    showChat: boolean;
    user: User | null;
    onToggleChatBox: () => void;
    onProfileClick: () => void;
}

export const NavBusiness: React.FC<NavJobSeekerProps> = ({ className, children, showChat, user, onToggleChatBox, onProfileClick }) => {
    const {photoUrl}= useProfileUrl();

    return (
        <div className="bg-blue-950 h-16 fixed left-0 right-0 text-gray-50">
               <div className="h-full px-primary-snug  flex items-center justify-between ">
                <div className="flex-none flex items-center space-x-4">
                 <div onClick={onProfileClick} className="rounded-full bg-gray-100  h-8 w-8 overflow-hidden flex items-center">
                            <img alt="" className="object-contain" src={photoUrl}/>
                    </div>
                    {/* <NavSearchBar /> */}
                 </div>
                <div className=" hidden lg:block absolute left-1/2 transform -translate-x-1/2 ">
                    <NavCentralTitle name={user?.displayName||""} location={"New York"} position={"Software Engineer"} />
                </div>
                <div className="flex-none flex justify-end">
                    {children}
                </div>
         </div>
       </div>
    )
}
