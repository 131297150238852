import React, {useState} from 'react'
import { sentenceCase } from "change-case";
import {Icon } from "../Icons";

interface EditableWorkExperienceProps{
    name: string;
    onSave: (name:string, value:Array<WorkExperience>) => void;
    value: Array<WorkExperience>;
}

export const EditableWorkExperience: React.FC<EditableWorkExperienceProps> = ({ name, onSave, value }) => {
    const [isEditing, setIsEditing] = useState<boolean>(false);
    const [isNewExpFormOn, setIsNewExpFormOn] = useState<boolean>(false);
    
    const toggleIsEditing = () => {
        setIsEditing(!isEditing);
    }

    const onSubmit = (e: any) => {
        e.preventDefault();
        console.log(e.target.company.value);
        if (e.target.company.value.trim() === "" || e.target?.duration.value.trim() === "" || e.target.job.value.trim()==="") {
            return;
         }
        setIsNewExpFormOn(false);
        onSave(name, [...value, {
            company: e.target.company.value,
            duration: e.target.duration.value,
            job:e.target.job.value,
        }])
    }

    const onDelete = (index:number) => {
        onSave(name, value.filter((item, i) => i!==index))
    }


    return (
        <div className="flex flex-col w-full space-y-2  ">
            <div className="flex justify-between">
                <div className="capitalize text-light">{sentenceCase(name)}</div>
                {!isEditing &&
                <div onClick={toggleIsEditing}><Icon name="pencil" /></div>
                }
                 {isEditing &&
                <div className="flex">
                  <div className="text-green-500 " onClick={toggleIsEditing} ><Icon name="check_fill" /></div>
                </div>
               }
            </div>


            <div className="flex flex-col">
                <div className=" flex flex-col leading-snug text-primary rounded-lg space-y-1 ">
                    {value?.map((o, i) => <div className={`${isEditing &&"hover:text-delete"} flex space-between space-x-2 border p-1 rounded `}>
                        <div className="flex-1 flex-col">
                            <div>{o.job}</div>
                            <div> {o.company}</div>
                            <div>{o.duration }</div>
                         </div>
                        {isEditing&&<div onClick={()=>onDelete(i)}><Icon name="close_fill" /></div>}
                    </div>)}
                </div>

                
                { isNewExpFormOn && <form onSubmit={onSubmit} className="flex space-x-2" >
                    <div className="flex-1 flex flex-col">
                        <input name={"job"} placeholder={"Job Name"} />
                        <input name={"company"} placeholder={"Company Name"} />
                        <input name={"duration"} placeholder={"Duration"} />
                    </div>
                    <button type="submit" ><Icon name="add_fill" /></button>
                </form>
                }
               
                {!isNewExpFormOn &&<div onClick={() => setIsNewExpFormOn(true)} className="text-primary-accent underline flex items-center space-x-2 cursor-pointer">
                    <Icon name="add_fill" />
                    <div>Add more</div>
                </div>}


                </div>
        </div>
    )
}
