import {Icon } from "../Icons";

interface CandidatePreviewCardProps extends BaseInterface {
    preferred_job_title: string;
    first_name: string;
    last_name: string;
    jobseeker_id: string;
    city: string;
    time: string;
    message_snippet: string;
}

export const CandidatePreviewCard: React.FC<CandidatePreviewCardProps> = ({ preferred_job_title, first_name = "s", last_name = "a", city = "New York", time = "10:50 pm", message_snippet, jobseeker_id }) => {
    
    return <div className="h-20 flex flex-row space-x-2  border py-2 px-1 cursor-pointer">
        <div className="flex-none h-full w-16 bg-purple-100 rounded-full flex items-center justify-center text-xl font-semibold text-purple-800">
            <div className="uppercase">{first_name[0]}</div>
        </div>
        <div className="flex-1 flex flex-col text-xs space-y-1">
            <div className="flex ">
                <div className="flex-none underline capitalize text-sm font-semibold">{preferred_job_title}</div>
                <div className="flex-1 flex justify-between text-xs pt-0.5 opacity-60">
                    <div className="text-green-700"><Icon name="verified"/></div>
                    <div className="">{time}</div>
                </div>
            </div>
            {/* <div className="text-sm font-thin leading-tight">{`${first_name} ${last_name[0]}. • ${city}`}</div> */}
            <div className="text-sm font-thin leading-tight">{`${jobseeker_id}. • ${city}`}</div>
            <div className="opacity-60">{message_snippet}</div>
        </div>
    </div>
}