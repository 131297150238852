import React, {useState} from 'react'
import { updateImpactStatements } from '../../api/JobSeekerActions/updateImpactStatements';
import { Icon} from "../Icons";
import { Modal } from './Modal';


const achievedVerbs:Array<string> = ["boosted","acquired","captured","conserved","converted","earned","gained","generated","maximized","negotiated","won","yielded"];
const didVerbs:Array<string> = ["spear-headed","accelerated", "accomplished", "analyzed","built","charted", "created","constructed","delivered","developed","executed","expanded","facilitated","finalized","forged","guided","handled","headed","improved","increased","implemented","instituted","operated","organized","produced","reached","simplified","volunteered"];


interface ImpactStatementContainerProps{

}


export const ImpactStatementContainer:React.FC<ImpactStatementContainerProps> = ({children}) => {
    return <div className="flex-col p-2 w-1/2 sm:w-1/3 md:1/4 flex-none rounded-lg bg-light">{children}</div>
}

interface ImpactStatementTitleProps{

}

export const ImpactStatementTitle: React.FC<ImpactStatementTitleProps> = ({ children }) => {
    return <div className="font-medium leading-6">{children }</div>
}


interface BlendedInputProps{
    value: string;
    onChange: (e: any) => void;
    id: string;
}

export const BlendedInput: React.FC<BlendedInputProps> = ({children, value, onChange, id}) => {
    return  <textarea name={id} className="bg-transparent  flex appearance-none" onChange={onChange} value={ value}></textarea>
}

interface VerbProps{
    value: string;
    onClick: () => void;
}

export const Verb: React.FC<VerbProps> = ({ value, onClick}) => {
    return <span onClick={onClick} className="space-x-2 text-primary-accent inline-flex cursor-pointer">
        <span>{value ?? "Select One"}</span>
        <span className="flex items-center"><Icon name="chevron_down"/></span>
    </span>
}


interface VideoStatementProps{

}

export const VideoStatement: React.FC<VideoStatementProps> = ({children}) => {
    return <div className="h-44 flex-none flex w-28 bg-gray-200 rounded-lg items-center justify-center text-purple-600 text-2xl">
        < Icon name="video"/>
    </div>
}


interface ImpactStatementProps{
    user?: User | null;
    value: ImpactStatement;
}


export const ImpactStatement: React.FC<ImpactStatementProps> = ({ value, user }) => {
    

    const [isModalOn, setIsModalOn] = useState<string|null>(null);


    const onChange = (e:any) => {
        updateImpactStatements({name_of_field:e.target.name,user_id:user?.uid||"",value:e.target.value})
    }
    
    const closeModal=()=> {
        setIsModalOn(null)
      }
    
      const openModal = (modalName:string) => {
          setIsModalOn(modalName);
      }
    
    const onAchievedVerbUpdate = ({value}:{value:string}) => {
        updateImpactStatements({user_id:user?.uid??"",name_of_field:"achieved_verb",value})
    }


    const onDidVerbUpdate = ({value}:{value:string}) => {
        updateImpactStatements({user_id:user?.uid??"",name_of_field:"did_verb",value})
    }

    
    return (
        <div className="w-full flex flex-col gap-4 ">
            <div className="text-gray-500 pt-4">Know more about me & my impact statement</div>
            <div className="flex overflow-x-auto gap-2">
                <VideoStatement />
                <ImpactStatementContainer>
                    <ImpactStatementTitle>    In my previous job, I served as a ... </ImpactStatementTitle>
                    <BlendedInput id="previous_job" onChange={onChange} value={value.previous_job }/>
                </ImpactStatementContainer>
                <ImpactStatementContainer >
                    <ImpactStatementTitle>  I <Verb onClick={()=>setIsModalOn("did")} value={ value.did_verb}/></ImpactStatementTitle>
                    <BlendedInput id="did_what" onChange={onChange} value={value.did_what }/>
                </ImpactStatementContainer>
                <ImpactStatementContainer>
                    <ImpactStatementTitle>  It <Verb onClick={()=>setIsModalOn("achieved")} value={value.achieved_verb }/>  </ImpactStatementTitle>
                    <BlendedInput id="achieved_what" onChange={onChange} value={value.achieved_what }/>
                </ImpactStatementContainer>
                <Modal title="" closeButtonName="Done" onClose={closeModal} show={isModalOn === "did"}>
                I <Verb onClick={()=>console.log("")} value={ value.did_verb}/>

                    <div className="flex flex-wrap items-center  gap-2 pt-2 pb-4">
                                {
                                    didVerbs.map((item) => {
                                        return <button onClick={()=>onDidVerbUpdate({value:item})}>{item}</button>
                                    })
                                }
                    </div>
                </Modal>
                <Modal title="" closeButtonName="Done" onClose={closeModal} show={isModalOn === "achieved"}>
                It <Verb onClick={()=>setIsModalOn("achieved")} value={value.achieved_verb }/>

                    <div className="flex flex-wrap items-center  gap-2 pt-2 pb-4">
                        
                    {
                            achievedVerbs.map((item) => {
                                return <button onClick={()=>onAchievedVerbUpdate({value:item})}>{ item}</button>
                            })
                      }
                      
                    </div>
                 </Modal>
            </div>
    </div>
    )
}
