import React, { useState } from 'react'
import { Icon, IconNames } from "../Icons";
import {capitalCase } from  "change-case";
import {ButtonSubmitPrimary} from "../../components/Common/ButtonSubmitPrimary"
import { applyJob} from "../../api/useSubscribeJobAction";
import { firebaseTimeStampToJsDate} from "../../utils/firebaseTimeStampToJsDate";
import {formatAMPM } from "../../utils/dateToAmPm";

interface ConversationProps extends BaseInterface {
    job_id: string;
    wage: string;
    business_name: string;
    city: string;
    time_applied: any;
    message_snippet: string;

}

const Conversation: React.FC<ConversationProps> = ({ job_id, wage, business_name, city="New York", time_applied="10:25 PM", message_snippet }) => {
    return <div className="h-20 flex-none flex flex-row space-x-2  border py-2 px-1">
        <div className="flex-none h-full w-16 bg-purple-100 rounded-full flex items-center justify-center text-xl font-semibold text-purple-800">
            <div className="uppercase">{business_name?.[0]??"V"}</div>
        </div>
        <div className="flex-1 flex flex-col text-xs">
            <div className="flex ">
                <div className="flex-none underline capitalize text-base font-semibold">{capitalCase(job_id)}</div>
                <div className="flex-1 flex justify-between text-xs pt-0.5 opacity-60">
                    <div className="">{`$${wage}/hr`}</div>
                    <div className="">{time_applied.toString()}</div>
                </div>
            </div>
            <div className="">{`${business_name} • ${city}`}</div>
            <div className="opacity-60">{message_snippet}</div>
        </div>
    </div>
}

interface PassedCardProps extends BaseInterface {
    job_id: string;
    wage: string;
    business_name: string;
    city: string;
    time_passed: any;
    message_snippet: string;
    job_posting_id: string;
    onApplyClick: (job_posting_id:string) => void;
}

export const PassedCard: React.FC<PassedCardProps> = ({ job_id, wage, business_name, city="New York", time_passed="10:25 PM", message_snippet, onApplyClick , job_posting_id}) => {
    return <div className="h-20 flex-none flex flex-row space-x-2  border py-2 px-1">
        <div className="flex-none h-full w-16 bg-purple-100 rounded-full flex items-center justify-center text-xl font-semibold text-purple-800">
            <div className="uppercase">{business_name?.[0]??"V"}</div>
        </div>
        <div className="flex-1 flex flex-col text-xs">
            <div className="flex ">
                <div className="flex-none underline capitalize text-base font-semibold">{capitalCase(job_id)}</div>
                <div className="flex-1 flex justify-between text-xs pt-0.5 opacity-60">
                    <div className="">{`$${wage}/hr`}</div>
                    <div className="">{time_passed.toString()}</div>
                </div>
            </div>
            <div className="">{`${business_name} • ${city}`}</div>
            <div className="flex"><ButtonSubmitPrimary className="px-2 py-1 " active={true} onClick={() => onApplyClick(job_posting_id)} > apply</ButtonSubmitPrimary></div>
        </div>
    </div>
}

interface InterviewCardProps extends BaseInterface{
    job_id: string;
    wage: string;
    business_name: string;
    city: string;
    time_invited: any;
    message_snippet: string;
    job_posting_id: string;
}

export const InterviewCard: React.FC<InterviewCardProps> = ({ job_id, wage, business_name, city="New York", time_invited="10:25 PM", message_snippet, job_posting_id}) => {
    return <div className="h-20 flex-none flex flex-row space-x-2  border py-2 px-1">
        <div className="flex-none h-full w-16 bg-purple-100 rounded-full flex items-center justify-center text-xl font-semibold text-purple-800">
            <div className="uppercase">{business_name?.[0]??"V"}</div>
        </div>
        <div className="flex-1 flex flex-col text-xs">
            <div className="flex ">
                <div className="flex-none underline capitalize text-base font-semibold">{capitalCase(job_id)}</div>
                <div className="flex-1 flex justify-between text-xs pt-0.5 opacity-60">
                    <div className="">{`$${wage}/hr`}</div>
                    <div className="">{time_invited}</div>
                </div>
            </div>
            <div className="">{`${business_name} • ${city}`}</div>
        </div>
    </div>
}








interface ChatTabProps extends BaseInterface {
    frequency: number;
    iconName: IconNames;
    active: boolean;
    name: string;
    onClick: (tabName: string) => void;
}

const ChatTab: React.FC<ChatTabProps> = ({ active, iconName, frequency, name, onClick }) => {

    return <div onClick={() => onClick(name)} className={`${active && "border-b-4 border-accent"} h-full w-12 flex items-center space-x-1 cursor-pointer text-purple-700`}>
        <div className="text-md"> <Icon name={iconName} /></div>
        <div className="text-xs">{frequency > 99 ? `99+` : frequency}</div>
    </div>
}


interface ChatBoxProps extends BaseInterface {
    show: boolean;
    onClose: () => void;
    user: User | null;
    subscribedJobs: Array<any>;
}

export const ChatBox: React.FC<ChatBoxProps> = ({ show, onClose, user, subscribedJobs }) => {
    const [chatBoxState, setChatBoxState] = useState<string>("interview");

    const onTabChange = (newTabName: string) => {
        setChatBoxState(() => newTabName);
    }

    const onApplyClick = (job_listing_id:string) => {
         applyJob({job_listing_id,user_id:user?.uid||""})
    }

    if (!show) return null;

    return <div className="w-full h-screen shadow-lg border-t flex flex-col bg-white z-50">
        <div onClick={onClose} className="flex-none h-6 bg-blue-950 text-gray-100 text-lg px-1"><Icon name="minus" /> </div>
        <div className="flex-none px-2 h-10 flex space-x-2">
            <ChatTab onClick={onTabChange} name={"passed"} active={chatBoxState === "passed"} frequency={subscribedJobs.filter(i=>i.passed===true).length??0} iconName="briefcase_new" />
            <ChatTab onClick={onTabChange} name={"applied"} active={chatBoxState === "applied"} frequency={subscribedJobs.filter(i=>(i.applied===true && !!!i?.is_invited) ).length} iconName="briefcase" />
            <ChatTab onClick={onTabChange} name={"interview"} active={chatBoxState === "interview"} frequency={subscribedJobs.filter(i=>(i.applied===true && i.is_invited===true) ).length} iconName="video" />
        </div>
        <div className="h-7 border text-sm font-semibold px-2 capitalize text-secondary-accent flex items-center"><div>{chatBoxState}</div></div>

        <div className="flex flex-col overflow-y-auto">
        {chatBoxState === "passed" && subscribedJobs?.map((subscribedJob) => {
            if (subscribedJob?.passed === true) {
                return <PassedCard {...subscribedJob} time_passed={`${formatAMPM(firebaseTimeStampToJsDate(subscribedJob?.time_passed))??""}`} onApplyClick={onApplyClick} ></PassedCard>
            }
            return null;
        })
        }
        {chatBoxState === "applied" && subscribedJobs?.map((subscribedJob) => {
            if (subscribedJob?.applied === true && !!!subscribedJob?.is_invited) {
                return <Conversation {...subscribedJob} time_applied={`${formatAMPM(firebaseTimeStampToJsDate(subscribedJob?.time_applied))??""}`} ></Conversation>
            }
            return null;
        })
        }
        {chatBoxState === "interview" && subscribedJobs?.map((subscribedJob) => {
            if (subscribedJob?.applied === true && subscribedJob?.is_invited===true) {
                return <InterviewCard {...subscribedJob} time_invited={`${formatAMPM(firebaseTimeStampToJsDate(subscribedJob?.time_applied))??""}`} ></InterviewCard>;
            }
            return null;
        })
            }
            </div>
    </div>
}

