import React, { useState} from 'react'
import { NavJobSeeker} from "../components/NavJobSeeker";
import { JobSeekerJobListing } from "../components/JobSeekerJobListing";
import { SideNav,SideNavMenuCategory,SideNavMenuItem} from "../components/SideNav";
import {useHistory } from "react-router-dom";
import { JobSeekerProfile } from "../components/JobSeekerProfile";
import {jobseekersRef, db } from "../firebase";
import { useDocument } from 'react-firebase-hooks/firestore';

interface JobSeekerProps extends BaseInterface{
    user: User | null;
    onLogOutClick: () => void;
}


export const JobSeeker: React.FC<JobSeekerProps> = ({ className, children, user, onLogOutClick }) => {
    const [rightSectionState, setRightSectionState] = useState<null|string>("chat");
    const [isLeftSideBarOn, setIsLeftSideBarOn] = useState<boolean>(false);
    const [middleSectionState, setMiddleSectionState] = useState<string>("job_listing");


    const [profileData] = useDocument(
       db.collection("jobseekers").doc(`${user?.uid}`),
        {
          snapshotListenOptions: { includeMetadataChanges: true },
        }
    );

    const history = useHistory();

    const toggleChatBox = () => {
        setRightSectionState(current => {
            if (current === "chat") {
                return ""
            }
            else {
                return "chat";
            }
        })
    }

    const onSideBarToggle = () => {
        setIsLeftSideBarOn(current => !current);
    }

    const onJobPreferenceClick = () => {
        setMiddleSectionState(() => "preference");

    }

    const onViewProfileClick = () => {
        setMiddleSectionState((current) => {
            if (current === "job_listing") {
                return "profile";
            }
            return "profile";
        })
   
    }

    const onHomeClick = () => {
        setMiddleSectionState((current) => {
            if (current === "profile") {
                return "job_listing";
            }
            return "job_listing";
        })
        onSideBarToggle();
    }
    

    const logOut = () => {
        onLogOutClick();
        history.push("/");
    }

  

    return (
        <div className={`${className}`}>
            <NavJobSeeker onProfileClick={onSideBarToggle} onToggleChatBox={toggleChatBox} showChat={rightSectionState === "chat"} user={user} location={profileData?.data()?.city??"New York"} job_title={profileData?.data()?.preferred_job_title}/>
            <SideNav onLogOutClick={logOut} isJobSeekerSideNav={true} onJobPreferenceClick={onJobPreferenceClick} onViewProfileClick={onViewProfileClick} onSideBarToggle={onSideBarToggle} user={user} onClose={onSideBarToggle} show={isLeftSideBarOn}>
                <SideNavMenuItem onClick={onHomeClick } name="Home" subTitle="Jobseeker" iconName="home" />
                        <SideNavMenuItem onClick={onHomeClick } name="My Jobs" subTitle="Applied and passed jobs"  iconName="briefcase" />
                        <SideNavMenuItem onClick={onHomeClick } name="Chat" subTitle="Last activity on Mar 28, 2021"  iconName="message" />
                         <SideNavMenuCategory name="Settings"/>
                        <SideNavMenuItem onClick={onHomeClick } name="Account Login" subTitle="Password and security" iconName="lock" />
                        <SideNavMenuItem onClick={onHomeClick } name="Location Settings" subTitle="New York, NY, USA"  iconName="profile_setting" />
                        <SideNavMenuItem onClick={onHomeClick } name="User verification" subTitle="+1 212 4361133" iconName="verified" />
            </SideNav>
            <div className="mt-16 w-full">

               {middleSectionState === "job_listing" && <JobSeekerJobListing user={user} />}
                <JobSeekerProfile state={middleSectionState} onJobPreferenceClick={onJobPreferenceClick} onViewProfileClick={onViewProfileClick} user={user} profileData={profileData?.data() as UserProfile } ></JobSeekerProfile>
            </div>
        </div>
    )
}
