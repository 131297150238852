import React from 'react'


interface LogoProps{
    className?: string;
}


const onLogoClick = () => {
    window.location.href = "/";
}


const Logo:React.FC<LogoProps> = ({className}) => {
    return (
        <div onClick={onLogoClick} className={`${className} font-extrabold text-xl cursor-pointer`}>
            HireX
        </div>
    )
}

export { Logo }
