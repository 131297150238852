
import React from 'react'
import {Icon,IconNames } from "../Icons";
import {Button } from "../Common";
import Switch from "react-switch";
import {EditableText } from "../EditableText";
import {updateTextField, updateArray } from "../../api/JobSeekerActions";


type dict<T> = {
    [key: string]: T;
}

const Day:dict<string> = {
    "1": "Mon",
    "2": "Tue",
    "3": "Wed",
    "4": "Thu",
    "5": "Fri",
    "6": "Sat",
    "7":"Sun"
}

const HoursForShift:dict<Array<string>> = {
    "Morning": ["5", "6", "7", "8", "9", "10", "11"],
    "Afternoon": ["12", "13", "14", "15", "16"],
    "Evening": ["17", "18", "19", "20"],
    "Night":["21","22","23","24","1","2","3","4"],
}



interface ShiftInDayCardProps extends BaseInterface{
    iconName: IconNames;
    time: string;
    shift: string;
    onClick: (shiftName: string) => void;
    active: boolean;
}

interface DayCardProps extends BaseInterface{
    dayNum: string;
    active: boolean;
    onClick : (dayNum: string) => void;
}

export const ShiftInDayCard: React.FC<ShiftInDayCardProps> = ({iconName,shift,time, onClick, active}) => {
    return <div onClick={() => onClick(shift)} className={`${active && "bg-primary-dark text-primary-light hover:opacity-90"}  h-20 w-24 py-1 flex flex-col items-center justify-center  border rounded-lg shadow-md cursor-pointer transition duration-100 ease-in hover:opacity `}>
        <div><Icon name={iconName}/></div>
        <div>{shift}</div>
        <div className="opacity-50 text-sm">{time }</div>
    </div>
}

export const OtherTimeSlotCard: React.FC = ({children}) => {
    return <Button type="secondary" onClick={()=>console.log("Adding more time slots")} className="h-10 border">Add more time slots</Button>
}

export const DayCard: React.FC<DayCardProps> = ({ children,onClick , dayNum, active}) => {
    return <div key={dayNum} className={` ${active&&"bg-primary-dark text-primary-light hover:opacity-90"} h-8 w-12 border rounded-lg flex justify-center items-center cursor-pointer`} onClick={() => onClick(dayNum)}>{Day[dayNum]}</div>
}


interface AvailabilityProps extends BaseInterface{
    onSaveClick: (name:string, value:Availability) => void;
    user: User | null;
    value: Availability;
    name: string;
}


export const Availability: React.FC<AvailabilityProps> = ({ className = "", children, onSaveClick, user, value, name }) => {
     
    const handleShiftClick = (shiftName: string) => {

        if (HoursForShift[shiftName].every(hour => value.available_shifts?.includes(hour))) {
            const filteredHours = value.available_shifts.filter(shift => !HoursForShift[shiftName].includes(shift));
            onSaveClick(name, { available_days: value.available_days, available_shifts: filteredHours });
            return;
        }
      
        const newAvArray =value.available_shifts ? [...value.available_shifts]:[];
        HoursForShift[shiftName].forEach((hour) => {
            if (!newAvArray.includes(hour)) {
                newAvArray.push(hour)
            }
        })
       onSaveClick(name, { available_days: value.available_days, available_shifts: newAvArray });
    }

    const handleDayClick = (dayId:string) => {
        if (value.available_days.includes(dayId)) {
          const newAvDays=  value.available_days.filter((day) => day !== dayId);
           onSaveClick(name, {available_days:newAvDays,available_shifts:value.available_shifts})
        }
        else {
            const newAvDays = [...value.available_days, dayId];
            onSaveClick(name, {available_days:newAvDays,available_shifts:value.available_shifts})
        }
    }

    const onRadioToggle = () => {
        if (!value.available_days) {
            onSaveClick(name,{available_days:["1", "2", "3", "4", "5", "6", "7"],available_shifts:value.available_shifts??[]})
            return;
        }
        if (["1", "2", "3", "4", "5", "6", "7"].every(i => value.available_days.includes(i))) {
            onSaveClick(name, { available_days: [], available_shifts: value.available_shifts })
            return;
        }
         onSaveClick(name,{available_days:["1", "2", "3", "4", "5", "6", "7"],available_shifts:value.available_shifts})
    }
    

    return (
        <>
        <div className="h-full w-full flex flex-col space-y-10 ">
              <div className="flex items-center justify-between space-x-2">
                    <label className="text-light">I'm available all days  </label>
               <Switch onChange={onRadioToggle} checked={value.available_days?["1", "2", "3", "4", "5", "6", "7"].every(i=>value.available_days.includes(i)):false}></Switch>
              </div>
            <div className="flex ">
                {
                    ["1", "2", "3", "4", "5", "6", "7"].map((item, i) => {
                        return <DayCard active={value.available_days?value.available_days.includes(item):false} onClick={handleDayClick } dayNum={item}/>
                    })
                }
            </div>
            <div className="flex  space-x-2">
            <ShiftInDayCard active={ HoursForShift["Morning"].every(hour=>value.available_shifts?.includes(hour)) } onClick={handleShiftClick } iconName="morning" time="5am-12pm" shift="Morning" />
            <ShiftInDayCard active={ HoursForShift["Afternoon"].every(hour=>value.available_shifts?.includes(hour))} onClick={handleShiftClick} iconName="afternoon" time="12pm-5pm" shift="Afternoon"/>
            <ShiftInDayCard active={ HoursForShift["Evening"].every(hour=>value.available_shifts?.includes(hour))} onClick={handleShiftClick} iconName="evening" time="5pm-9pm" shift="Evening"/>
            <ShiftInDayCard active={HoursForShift["Night"].every(hour=>value.available_shifts?.includes(hour))} onClick={handleShiftClick} iconName="night" time="9pm-4am" shift="Night" />
            </div>

            </div>
            </>
    )
}

interface UserPreferenceProps{
    user: User | null;
    profileData: UserProfile;
}


export const UserPreference: React.FC<UserPreferenceProps> = ({ user, profileData }) => {
    

    const onSaveEditableText = (name: string, value: string|any) => {
       updateTextField({user_id:user?.uid||"",payload:{name, value}})
    }

    const onSavePreferredJobTitle = (name: string, value: string|any) => {
        updateArray({ user_id: user?.uid || "", payload: { name: "skills", value: [] } });//deleting skills because job_title changed
        updateTextField({user_id:user?.uid||"",payload:{name, value}})
    }

    return (
        <>
        <div className="flex flex-col p-2 space-y-4">
            <div className="flex justify-between border-b pb-1">
                    <EditableText name="preferred_job_title" onSave={onSavePreferredJobTitle} value={ profileData?.preferred_job_title}/>
            </div>
            <div className="flex justify-between border-b pb-1">
                    <EditableText name="preferred_hourly_rate" onSave={onSaveEditableText} value={ `${profileData?.preferred_hourly_rate}`}/>
            </div>
          <Availability name="availability" onSaveClick={onSaveEditableText} user={user} value={profileData?.availability} />
        </div>
            <div className="hidden md:block border bg-gray-300">
         
            </div>
            </>
    )
}
