import firebase from 'firebase';

const firebaseConfig = {
    apiKey: "AIzaSyBzmY_GGn8w7SsY3o5ceKGwqHuWiz3zdeE",
    authDomain: "testproj-6a375.firebaseapp.com",
    databaseURL: "https://testproj-6a375-default-rtdb.firebaseio.com",
    projectId: "testproj-6a375",
    storageBucket: "testproj-6a375.appspot.com",
    messagingSenderId: "638689784860",
    appId: "1:638689784860:web:75ac7f357cbb438f649bd9",
    measurementId: "G-27WGM3CRTG"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
const auth = firebase.auth();
const db = firebase.firestore();

//initiating references to the databases
const jobseekersRef = db.collection('jobseekers')

const businessesRef = db.collection("businesses");

const jobseekerSkillsRef = db.collection("jobseeker_skills");

// for privileges purposes
const functions = firebase.functions();

//for storage
const storage = firebase.storage();

//google provider sign-in
const googleProvider = new firebase.auth.GoogleAuthProvider();


export { firebase, storage, db, auth, functions, googleProvider,jobseekersRef, businessesRef,jobseekerSkillsRef  }