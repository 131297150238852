import {db } from "../../firebase";

export const updateImpactStatements = async ({ name_of_field, user_id, value }: { user_id: string, name_of_field: string, value: string }) => {
   
    try {
        await db.collection("jobseekers").doc(user_id).update({[`impact_statement.${name_of_field}`]:value});
    }
    catch (err) {
        console.log(err)
    }
}
