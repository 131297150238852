import React, {useEffect} from 'react'
import { SectionLabelWithEdit } from './SectionLabelWithEdit'
import {Icon } from "../Icons";
import { updateArray } from "../../api/JobSeekerActions/updateArray";



const SkillDictionary: { [key:number]:string}={
    0:"Basic Knowledge",
    1:"Limited Experience",
    2:"Intermediate",
    3:"Advanced",
    4:"Expert"
}

interface SkillLevelCardProps{
    skill_id: string;
    skill_score: number;
    onOptionClick: (skill_id: string, score: number) => void;
}


export const SkillLevelCard:React.FC<SkillLevelCardProps> = ({skill_id,skill_score, onOptionClick}) => {
    return (
        <div  className="flex-none rounded-lg shadow-lg max-w-sm flex flex-col">
            <div className="bg-gray-200 capitalize py-3 px-2 rounded-t-lg rounded-b-none">{skill_id}</div>
           <div className="p-2"> {[0, 1, 2, 3, 4].map((item, i) => {
                return <div onClick={()=>onOptionClick(skill_id,item) } className="flex items-center space-x-2"> 
                    <div >{
                     skill_score===item ? <Icon name="radio_checked"/>:<Icon name="radio_unchecked"/>
                    }</div>
                    <div>{SkillDictionary[item] }</div>
                 </div>
           })}
                </div>
        </div>
    )
}



interface SkillsProps{
    value: Array<JobseekerSkill>;
    user: User ;
}

export const Skills:React.FC<SkillsProps> = ({user, value}) => {
     


    const onOptionClick = (skill_id: string, score: number) => {
        
        const i = value.findIndex((item) => item.skill_id === skill_id);

        if (i === -1) {
            updateArray({
                user_id: user?.uid || "", payload: {
                    name: "skills",
                    value:[...value,{skill_id:skill_id,skill_score:score}]
                }
            })
            return;
        }
        updateArray({
            user_id: user?.uid || "", payload: {
                name: "skills",
                value: value.map((item) => {
                    if (item.skill_id === skill_id) {
                        return {
                            skill_id: item.skill_id,
                            skill_score:score,
                        }
                    }
                    return item;
                })
            }
        })
       
    }

    return (
        <div className="w-full flex flex-col gap-4 pt-4">
            <SectionLabelWithEdit name="Skills" />
            <div className="flex overflow-x-auto gap-2">
                {
                    value.map((skill) => {
                        return <SkillLevelCard key={skill.skill_id} onOptionClick={onOptionClick} skill_id={skill.skill_id } skill_score={skill.skill_score} />
                    })
                }
            </div>
        </div>
    )
}
