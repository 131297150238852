import React from 'react'
import { LanguagesCard} from "./LanguagesCard";
import {KeywordsCard} from "./KeywordsCard";
import {Icon } from "../../components/Icons";
import { SkillLevelCard } from "./SkillLevelCard";
import { SoftSkills} from "./SoftSkills";
import {SectionLabel } from "./SectionLabel";
import { ImpactStatementContainer,ImpactStatementTitle} from "../JobSeekerProfile/ImpactStatement";

interface CandidateInfoCardProps{
    jobseeker_id:string;
    days_since_applied: number;
    first_name: string;
    last_name: string;
    job_id: string;
    city: string;
    summary: string;
    skills: Array<any>;
    languages: Array<string>;
    headline?: string;
    impact_statement?: any;
    professional_summary?: string;
    soft_skills?: Array<string>;
    work_experiences?: any;
    education?: any;
}

export const CandidateInfoCard: React.FC<CandidateInfoCardProps> = ({ days_since_applied=1,city,first_name="John",job_id,last_name="Doe",skills,summary, languages, headline, soft_skills, impact_statement, professional_summary, work_experiences, education, jobseeker_id}) => {
    return (
        <div className="flex flex-col space-y-4 p-3 ">
            <div className="flex flex-col">
                <div className="text-xs text-gray-500">{`Applied ${days_since_applied} day ago`}</div>
                <div className="text-base font-semibold flex items-center space-x-1">
                    <div>{`${jobseeker_id}`}</div>
                    <div className="text-green-600"><Icon name="verified"/></div>
                </div>
                <div className="text-sm capitalize">{job_id}</div>
                <div className="text-xs capitalize text-gray-500">{ city}</div>
            </div>
   
            <div className="flex gap-4 ">
                <LanguagesCard languages={  languages }/>
                <KeywordsCard keywords={soft_skills} />
            </div>
          
            <div className="flex flex-col gap-4 divide-y ">
            <div className=" flex flex-col pt-2">
                <SectionLabel name="Headline" />
                <div className="">
                    {headline??"No headline"}
                </div>
            </div>

            <div className=" flex flex-col pt-2">
                <SectionLabel name="Professional Summary" />
                <div className="">
                    {professional_summary??"No headline"}
                </div>
            </div>


            <div className="pt-2">
                <SectionLabel name="Soft Skills"/>
                <SoftSkills softSkills={ soft_skills||[]}/>
            </div>

            <div className="pt-2">
                <SectionLabel name="My Impact Statement"/>
                <div className="flex overflow-x-auto gap-2 h-48">
                <ImpactStatementContainer>
                        <ImpactStatementTitle>   In my previous job, I served as a  </ImpactStatementTitle>
                        <div className="text-primary-accent">{ impact_statement?.previous_job??"Not filled"}</div>
                </ImpactStatementContainer>
                    <ImpactStatementContainer >
                    <div className="flex space-x-2">
                        <ImpactStatementTitle>  I </ImpactStatementTitle>
                           <div className="text-primary-accent">{ impact_statement?.did_verb??"Not filled"}</div>
                           </div>
                            <div>{impact_statement?.did_what }</div>
                </ImpactStatementContainer>
                    <ImpactStatementContainer>
                        <div className="flex space-x-2">
                        <ImpactStatementTitle>  It  </ImpactStatementTitle>
                        <div className="text-primary-accent">{ impact_statement?.achieved_verb??"Not filled"}</div>
                        </div>
                         <div>{impact_statement?.achieved_what }</div>
                </ImpactStatementContainer>
              </div>
            </div>

            <div className="pt-2">
            <SectionLabel name="Language"/>
                {languages?.map((language) => {
                    return <div className="text-" key={ language}>{language }</div>
              })}
            </div>

                
            <div className="pt-2 flex flex-col ">
                <SectionLabel name="Work Experience" />
                    {work_experiences?.map((work_experience:any, i:number) => {
                       return <div className="flex flex-col" key={i}>
                           <div className="">{work_experience?.job}</div>
                           <div className="">{work_experience?.company}</div>
                           <div className="">{work_experience?.duration}</div>
                        </div>
                    })??"No work experience to display"}
             </div>
                
             <div className="pt-2 flex flex-col ">
                    <SectionLabel name="Education" />
                    {education?.map((education:any, i:number) => {
                       return <div className="flex flex-col" key={i}>
                           <div className="">{education?.major}</div>
                           <div className="">{education?.institution}</div>
                           <div className="">{education?.graduation_date}</div>
                        </div>
                    })??"No education to display"}
             </div>

            <div className="pt-2 flex flex-col ">
                <SectionLabel name="Skills" />
                <div className="flex-1 overflow-x-auto gap-2 h-48">
                   <div className="flex space-x-2">{skills?.map((skill, i) => <SkillLevelCard {...skill}/>)}</div>
                </div>
            </div>
        </div>
        </div>
    )
}
