import React, {useEffect, useState} from 'react'
import {ActionButtonJobListing } from "./ActionButtonCandidate";
import {  CandidateInfoCard} from "./CandidateInfoCard";
import {useCandidateInfo } from "../../api/Business/useCandidateInfo";
import { inviteCandidate,passCandidate } from "../../api/Business/CandidateAction";

interface BusinessCandidateSelectionProps{
    activeJobListingId: string | null;
    activeJobId?: string;
}

export const BusinessCandidateSelection: React.FC<BusinessCandidateSelectionProps> = ({ activeJobListingId, activeJobId }) => {
    const { candidatesDetails } = useCandidateInfo({ job_listing_id: activeJobListingId, job_id: activeJobId||""  });
    const [activeCandidateIndex, setActiveCandidateIndex] = useState<null|number>(null);
    

    useEffect(() => {
        if (candidatesDetails.length === 0) {
            return;
        }
      
        setActiveCandidateIndex(0);
    }, [candidatesDetails])

    const getNextCandidate = () => {
        if (activeCandidateIndex === null) {
            return;
        }
        if (activeCandidateIndex === candidatesDetails.length - 1) {
            setActiveCandidateIndex(null);
            return;
        }
        setActiveCandidateIndex(activeCandidateIndex + 1);
    }

    const inviteForInterview = async () => {
        if (activeJobListingId === null || activeCandidateIndex === null) {
            return;
        }
        await inviteCandidate({ job_listing_id: activeJobListingId, jobseeker_id: candidatesDetails[activeCandidateIndex].jobseeker_id });
        getNextCandidate();
    }

    const passJobSeeker = async () => {
        if (activeJobListingId === null || activeCandidateIndex === null) {
            return;
        }

        await passCandidate({ job_listing_id: activeJobListingId, jobseeker_id: candidatesDetails[activeCandidateIndex].jobseeker_id });
        getNextCandidate();
    }


    return (
        <div className="flex flex-col w-full">
            <div className="h-48 w-full bg-gray-300"> </div>
            <div className="hidden h-14 w-full border sm:flex space-x-2 py-1 px-1 shadow-xl">
                <ActionButtonJobListing primary={false} name="Pass for now" iconName="chevron_left" onClick={passJobSeeker}  />
                <ActionButtonJobListing primary={true} name="Invite for Interview" iconName="tick" onClick={inviteForInterview}/>
            </div>
            {(activeCandidateIndex !== null) && <CandidateInfoCard {...candidatesDetails[activeCandidateIndex]} />}
            
            <div className="fixed left-0 right-0 bottom-0 h-14 sm:hidden  border flex space-x-2 py-1 px-1 shadow-xl">
                <ActionButtonJobListing primary={false} name="Pass for now" iconName="chevron_left" onClick={passJobSeeker}  />
                <ActionButtonJobListing primary={true} name="Invite for Interview" iconName="tick" onClick={inviteForInterview}/>
            </div>
        </div>
    )
}
