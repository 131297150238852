import React from 'react'

interface SideNavMenuCategoryProps extends BaseInterface{
    name: string;
}


export const SideNavMenuCategory:React.FC<SideNavMenuCategoryProps> = ({name}) => {
    return (
        <div className="flex justify-between px-4 py-3 ">
            <div className="">{ name}</div>
      </div>
    )
}
