import React from 'react'
import { Logo} from "../Nav/Logo";
import { MoreOptions } from "./MoreOptions";
import {Social } from "./Social";

const Footer = () => {
    return (
        <div className="px-primary text-opacity-80 bg-primary-light text-primary-light h-28 flex items-center justify-between">
            <Logo />
            <MoreOptions/>
            <Social/>
        </div>
    )
}

export {Footer}
