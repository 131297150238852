import React from 'react'
import {  Button} from "../Common";

interface HeaderToggleContentProps extends BaseInterface{
    onSaveClick: () => void;
}

const stopPropagation:React.MouseEventHandler = (e) => {
    e.stopPropagation();
}


export const HeaderToggleContent: React.FC<HeaderToggleContentProps> = ({ className = "", children, onSaveClick }) => {
  

    return (
        <div onClick={stopPropagation} className={`${className} h-96  shadow-lg rounded-lg border-t  flex flex-col items-center   mt-6 mb-12 relative  `}>
             <div className="overflow-y-scroll h-full w-full">{children}</div>  
           
        </div>
    )
}
